const pre = "/web/v1";

export const EXPORT_EXCEL = { method: "post", url: `${pre}/diamond/excel`, isDownload: true, showLoader: true };

export const SEND_EMAIL_XRAY = { method: "post", url: `${pre}/diamond/x-ray-mail`, showLoader: true };

export const VIEW_DIAMOND = { method: "post", url: "/web/v1/diamond/view", showLoader: true };

export const DIAMOND_DATA_BY_ID = { method: "post", url: `${pre}/diamond/search/by-id`, showLoader: true };

export const LOGIN_USER = { method: "post", url: `${pre}/auth/login`, showLoader: true };

export const USER_LOGOUT = { method: "post", url: `${pre}/auth/logout`, showLoader: true };

export const DOWNLOAD_ZIP = { method: "post", url: `${pre}/diamond/download-zip`, isDownload: true, showLoader: true };

export const DOWNLOAD_FILE = { method: "post", url: `${pre}/download-file`, showLoader: true };

export const PROJECT_SETTING = { method: "post", url: `${pre}/project-setting/view`, showLoader: true };