import React, { useEffect, useState } from "react";
import { Card } from "antd";
import Slider from "react-slick";

import { getMediaUrl } from "services/Commonfunction";

import MagnifierImage from "components/DiamondDetail/MagnifierImage";
import { NextArrow, PrevArrow, Unavailable } from "./MiscComponent";

export default function NakedEye({ dnaData = {}, navFor, setNav, filter }) {
	const [sliderNakedEye, setSliderNakedEye] = useState([]);

	const sliderSettings = {
		fade: true,
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
	};

	useEffect(() => {
		async function fetchData() {
			let nakedEyeFilter = filter.slice();

			const sliderData = await Promise.all(
				nakedEyeFilter.map(async (obj) => {
					let url = "";
					if (dnaData[obj.value]) {
						url = await getMediaUrl(obj.value, dnaData);
					}

					return {
						...obj,
						url,
					};
				}),
			);

			setSliderNakedEye(sliderData);
		}

		fetchData();
	}, [filter.length, dnaData, filter]);

	return dnaData?.hndVideo && sliderNakedEye.length > 0 ? (
		<Card size="small" bordered={false} className="img-vid-container naked-eye">
			<Slider asNavFor={navFor} ref={setNav} {...sliderSettings} adaptiveHeight>
				{sliderNakedEye.map((dt, i) => (
					<div key={i}>
						{dt.isImage ? (
							<MagnifierImage image={dt?.url} />
						) : (
							<video controls autoPlay loop muted name="media">
								<source id="vidoshadecard" type="video/mp4" src={dt.url} />
							</video>
						)}

						<Card.Meta title={dt.title} className="text-center mt-5" />
					</div>
				))}
			</Slider>
		</Card>
	) : (
		<Unavailable styles={{ height: "565px" }} />
	);
}
