import { LocalStore } from "services/Storage";

import { axios } from "util/Api";
import { logout } from "util/logout";
import { catchError, isEmpty, logWarn } from "util/utils";

import { BASE_URL, DOWNLOAD_URL } from "constants/Common";

const handleuploadProgress = (progress) => {
	const loaded = Math.round((progress?.loaded * 100) / progress?.total);
	const showProgressDoc = document.querySelector(".api-loader .showProgress span");
	const showProgressMain = document.querySelector(".api-loader .showProgress");

	if (showProgressDoc) {
		if (showProgressMain?.classList?.contains?.("display-none")) {
			showProgressMain.classList.remove("display-none");
		}
		showProgressDoc.innerText = `${loaded}%`;
		showProgressDoc.style.width = `${loaded}%`;
	}
};

export const callApi = async (config, cb) => {
	const token = config.auth ?? config.updateToken ?? LocalStore.get("token");
	const baseURL = (() => {
		if (config.isDownload) return DOWNLOAD_URL;
		return BASE_URL;
	})();

	const request = {
		baseURL,
		url: config?.url,
		method: config?.method,
		data: config.payload ?? config.data ?? config?.request,
		headers: {
			...(!isEmpty(token) && { Authorization: `JWT ${token}` }),
			...(!config.isMultipart && { "Content-Type": "application/json" }),
			devicetype: config.deviceType ?? 1,
		},
		transformRequest: config?.isMultipart ? {} : undefined,
		onUploadProgress: config?.isMultipart ? handleuploadProgress : undefined,
	};

	const output = { error: undefined, response: undefined, data: undefined };

	try {
		const response = await axios.request(request);
		output.response = response;
		output.data = response?.data;
		output.error = response?.data?.code !== "OK";
	} catch (error) {
		logWarn(error);
		output.response = error?.response;
		output.data = error?.response;
		output.error = error;
	}

	const unauthorized = output?.data?.code === "E_UNAUTHORIZED";
	void (unauthorized ? logout() : catchError(() => cb(output.error, output.data)));

	return [output.error, output.data, output];
};