import React from "react";

import { Modal } from "antd";
import "./modal.less";

const CommonModal = (props) => {
	return (
		<div>
			<Modal
				title={props.title}
				className={`commonModal ${props.modalSize ? props?.modalSize : ""}`}
				visible={props.visible}
				onCancel={props.handleCancel}
				footer={
					props.footerShow ? (
						<div className="commonModalButton">
							<button className="fillButton" onClick={props.handleOk}>
								{props.submitTitle}
							</button>
							{props.handleCancel ? (
								<button className="outLineButton" onClick={props.handleCancel}>
									{props.cancelTitle}
								</button>
							) : (
								""
							)}
						</div>
					) : null
				}
			>
				{props.children}
			</Modal>
		</div>
	);
};

export default CommonModal;
