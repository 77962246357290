import Axios from "axios";
import Fingerprint from "fingerprintjs";

import { BASE_URL } from "constants/Common";

const getsessionUUID = () => new Fingerprint().get();

export const axios = Axios.create({
	baseURL: BASE_URL,
	timeout: 1000 * 60 * 10,
	headers: { "Content-Type": "application/json", uuid: getsessionUUID() },
});

export default axios;
