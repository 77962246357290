import moment from "moment";

import { LocalStore } from "services/Storage";

import { callApi } from "util/call-api";
import { isEmpty } from "util/utils";

const UtilService = {
	callApi,
	getFullName(user) {
		if (isEmpty(user)) return null;

		user.firstName = user.firstName ? user.firstName : "";
		user.lastName = user.lastName ? user.lastName : "";

		const fullName =
			user.firstName.charAt(0).toUpperCase() +
			user.firstName.slice(1).toLowerCase() +
			" " +
			user.lastName.charAt(0).toUpperCase() +
			user.lastName.slice(1).toLowerCase();

		return fullName;
	},
	getLocalStorageItem: (name) => LocalStore.get(name),
	setLocalStorageItem: (value, name) => LocalStore.set(name, value),

	/**
	 * Function used to get user ip
	 */
	async userIp() {
		let ipAddress = null;

		const userInfo = localStorage.getItem("fn-userInfo")
			? JSON.parse(localStorage.getItem("fn-userInfo"))
			: localStorage.getItem("fn-userInfo");

		const getStoreIp = userInfo && userInfo.ip ? userInfo.ip : null;
		const expireTime = userInfo && userInfo.expireTime ? userInfo.expireTime : null;

		if (getStoreIp && moment(expireTime).isAfter(moment.now())) {
			return getStoreIp;
		} else {
			ipAddress = await this.getUserIpAddress(5);

			const expireTime = moment(moment.now()).add(5, "hours").valueOf();
			const userIpInfo = {
				ip: ipAddress,
				expireTime: expireTime,
			};

			localStorage.setItem("fn-userInfo", JSON.stringify(userIpInfo));

			return ipAddress;
		}
	},

	/**
	 * Function used to get ip address from diff. third party ip
	 * @param {*} expression
	 */
	async getUserIpAddress(expression) {
		let ipAddress = null;

		switch (expression) {
			case 1:
				await fetch("https://ipapi.co/json")
					.then((response) => response.json())
					.then((jsonData) => {
						ipAddress = jsonData.ip;
					})
					.catch((error) => {
						console.error(error);
					});
				break;
			case 2:
				await fetch("https://ipinfo.io/json")
					.then((response) => response.json())
					.then((jsonData) => {
						ipAddress = jsonData.ip;
					})
					.catch((error) => {

						console.error(error);
					});

				break;
			case 3:
				await fetch("https://api.db-ip.com/v2/free/self")
					.then((response) => response.json())
					.then((jsonData) => {
						ipAddress = jsonData.ipAddress;
					})
					.catch((error) => {
						console.error(error);
					});

				break;
			case 4:
				await fetch("https://jsonip.com")
					.then((response) => response.json())
					.then((jsonData) => {
						ipAddress = jsonData.ip;
					})
					.catch((error) => {

						console.error(error);
					});

				break;
			case 5:
				await fetch("http://ip-api.com/json")
					.then((response) => response.json())
					.then((jsonData) => {
						ipAddress = jsonData.query;
					})
					.catch((error) => {
						console.error(error);
					});
				break;
			default:
				await fetch("https://api.ipify.org/?format=json")
					.then((response) => response.json())
					.then((jsonData) => {
						ipAddress = jsonData.ip;
					})
					.catch((error) => {

						console.error(error);
					});

				break;
		}

		return ipAddress;
	},
};

export default UtilService;
