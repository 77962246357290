import React, { useState } from "react";

import { Checkbox } from "antd";
import _isBoolean from "lodash/isBoolean";
import map from "lodash/map";

import { handleDownloadExcel, downloadZip } from "../../DiamondList/DiamondListFunctions";
import OpenNotification from "../CommonButton/OpenNotification";
import { isEmpty } from "util/utils";

const DownloadDropdown = React.memo((props) => {
	const [isExcel, setIsExcel] = useState(false);
	const [isCert, setIsCert] = useState(false);
	const [typeIIA, setTypeIIA] = useState(false);
	const [isImg, setIsImg] = useState(false);
	const [isImg2, setIsImg2] = useState(false);
	const [isFaceUp, setIsFaceUp] = useState(false);
	const [DarkField, setDarkField] = useState(false);
	const [Ideal, setIdeal] = useState(false);
	const [flourescence, setFlourescence] = useState(false);
	const [isVideo, setIsVideo] = useState(false);

	// Naked Eyes
	const [isNaked, setIsNaked] = React.useState(false);

	const [handImage, setHandImage] = React.useState(false);
	const [shadeCard, setShadeCard] = React.useState(false);
	const [isFinger, setIsFinger] = React.useState(false);
	const [isTweezer, setIsTweezer] = React.useState(false);
	const [disableAll, setDisableAll] = React.useState(isEmpty(props?.checked));

	const [isPlt, setIsPlt] = useState(false);
	const [isHa, setIsHa] = useState(false);
	const [isAsset, setIsAsset] = useState(false);
	const [isArrow, setIsArrow] = useState(false);
	const [isVideo2, setIsVideo2] = useState(false);
	const [roughImage, setRoughimage] = useState(false);
	const [roughVideo, setRoughVideo] = useState(false);
	const [DImage, setDImage] = useState(false);
	const [images, setImages] = useState(false);
	const [videos, setVideos] = useState(false);
	const [certificates, setCertificates] = useState(false);
	const [roughs, setRoughs] = useState(false);
	const path = window.location.pathname.split("/")[1];

	const flagRef = React.useRef(0);

	const ShareOption = React.useCallback((props) => {
		return (
			<li className={props.activeClass}>
				<div className="dropdownBottomItem">
					<div className="dropdownIconCheckBox">
						<Checkbox
							onChange={props.onChange}
							onClick={() => {
								void props.onClick?.();
								flagRef.current = flagRef.current + props.checked ? -1 : 1;
							}}
							checked={props.checked}
						></Checkbox>
					</div>
					<span className="shareOptionLabel">{props.title}</span>
				</div>
			</li>
		);
	}, []);

	const handleDownloadClick = () => {
		if (
			isAsset ||
			isHa ||
			isCert ||
			isExcel ||
			isImg ||
			isPlt ||
			isVideo ||

			// Naked Eyes
			handImage ||
			shadeCard ||
			isFinger ||
			isTweezer ||
			isArrow ||
			isImg2 ||
			typeIIA ||
			isFaceUp ||
			DarkField ||
			Ideal ||
			flourescence ||
			isVideo2 ||
			roughImage ||
			roughVideo ||
			DImage
		) {
			if (props.checked.length === 1) {
				if (
					!isAsset &&
					!isHa &&
					!isCert &&
					isExcel &&
					!isImg &&
					!isPlt &&
					!isVideo &&
					// Naked Eyes
					!handImage &&
					!shadeCard &&
					!isFinger &&
					!isTweezer &&
					!isArrow &&
					!isImg2 &&
					!typeIIA &&
					!isFaceUp &&
					!DarkField &&
					!Ideal &&
					!flourescence &&
					!isVideo2 &&
					!roughImage &&
					!roughVideo &&
					!DImage
				) {
					if (path === "upcoming") {
						handleDownloadExcel({}, props.checked[0].id, () => { }, path === "upcoming");
					} else {
						props.handleLoader(true);

						handleDownloadExcel(
							// {},                                                           //comment for IGI search
							props?.seachFilterTag ? props.seachFilterTag.searchData : {}, //for IGI search
							props.orderDiamond
								? props.checked[0].diamondId
								: props?.quoteList
									? props?.checked[0].diamond
									: props.checked[0].id,
							() => {
								props.handleLoader(false);
							},

							props.checked[0].wSts === "U" ? true : false,
							[],
							props.orderDiamond,
						);
					}
				} else if (flagRef.current === 1) {
					const obj = {
						filter: {
							vStnId: map(props.checked, "vStnId"),
							lb: map(props?.checked, "lb"), //IGISearch Cert dwnld
						},
						...(props.orderDiamond && { orderDiamond: true }),
						isExcel: isExcel,
						img: isImg,
						certFile: isCert,
						videoFile: isVideo,
						// Naked Eyes
						hndImg: handImage,
						shdCd: shadeCard,
						hndVideo: isFinger,
						isTwz: isTweezer,
						hAFile: isHa,
						arrowFile: isArrow,
						assetFile: isAsset,
						pltFile: isPlt,
						mp4Video: isVideo2,
						type2: typeIIA,
						roughVideo: roughVideo,
						darkFieldImage: DarkField,
						flsImage: flourescence,
						idealWhiteImage: Ideal,
						roughImage: roughImage,
						planImg: DImage,
						faceUp: isFaceUp,
						b2cRealImage: isImg2,
					};

					downloadZip(obj, (res) => {
						if (res) {
							OpenNotification({
								type: "success",
								title: "Wait for sometime, your download is in process. File(s) will be downloaded soon.",
							});
							props.clearAll();
							props.onCloseDownloadPopup();
						}
					});
				} else if (flagRef.current >= 2) {
					const obj = {
						filter: {
							vStnId: map(props.checked, "vStnId"),
							lb: map(props?.checked, "lb"), //IGISearch Cert dwnld
						},
						...(props.orderDiamond && { orderDiamond: true }),
						isExcel: isExcel,
						img: isImg,
						certFile: isCert,
						videoFile: isVideo,
						// Naked Eyes
						hndImg: handImage,
						shdCd: shadeCard,
						hndVideo: isFinger,
						isTwz: isTweezer,
						hAFile: isHa,
						arrowFile: isArrow,
						assetFile: isAsset,
						pltFile: isPlt,
						mp4Video: isVideo2,
						type2: typeIIA,
						roughVideo: roughVideo,
						darkFieldImage: DarkField,
						flsImage: flourescence,
						idealWhiteImage: Ideal,
						roughImage: roughImage,
						planImg: DImage,
						faceUp: isFaceUp,
						b2cRealImage: isImg2,
						zip: true,
					};

					downloadZip(obj, (res) => {
						if (res) {
							OpenNotification({
								type: "success",
								title: "Wait for sometime, your download is in process. File(s) will be downloaded soon.",
							});
							props.clearAll();
							props.onCloseDownloadPopup();
						}
					});
				} else if (flagRef.current < 0) {
					const obj = {
						filter: {
							vStnId: map(props.checked, "vStnId"),
							lb: map(props?.checked, "lb"), //IGISearch Cert dwnld
						},
						...(props.orderDiamond && { orderDiamond: true }),
						isExcel: isExcel,
						img: isImg,
						certFile: isCert,
						videoFile: isVideo,
						// Naked Eyes
						hndImg: handImage,
						shdCd: shadeCard,
						hndVideo: isFinger,
						isTwz: isTweezer,
						hAFile: isHa,
						arrowFile: isArrow,
						assetFile: isAsset,
						pltFile: isPlt,
						mp4Video: isVideo2,
						type2: typeIIA,
						roughVideo: roughVideo,
						darkFieldImage: DarkField,
						flsImage: flourescence,
						idealWhiteImage: Ideal,
						roughImage: roughImage,
						planImg: DImage,
						faceUp: isFaceUp,
						b2cRealImage: isImg2,
						zip: true,
					};

					downloadZip(obj, (res) => {
						if (res) {
							OpenNotification({
								type: "success",
								title: "Wait for sometime, your download is in process. File(s) will be downloaded soon.",
							});
							props.clearAll();
							props.onCloseDownloadPopup();
						}
					});
				}
			} else {
				if (
					!isAsset &&
					!isHa &&
					!isCert &&
					isExcel &&
					!isImg &&
					!isPlt &&
					!isVideo &&
					// Naked Eyes
					!handImage &&
					!shadeCard &&
					!isFinger &&
					!isTweezer &&
					!isArrow &&
					!isImg2 &&
					!typeIIA &&
					!isFaceUp &&
					!DarkField &&
					!Ideal &&
					!flourescence &&
					!isVideo2 &&
					!roughImage &&
					!roughVideo &&
					!DImage
				) {
					handleDownloadExcel(
						// {},                                                           //comment for IGI search
						props?.seachFilterTag ? props.seachFilterTag.searchData : {}, //for IGI search
						props.orderDiamond
							? map(props.checked, "diamondId")
							: props?.quoteList
								? map(props.checked, "diamond")
								: map(props.checked, "id"),
						() => { },
						props.checked[0].wSts === "U" ? true : false,
						[],
						props.orderDiamond,
					);
				} else {
					const obj = {
						filter: {
							vStnId: map(props.checked, "vStnId"),
							lb: map(props?.checked, "lb"), //IGISearch Cert dwnld
						},
						...(props.orderDiamond && { orderDiamond: true }),
						isExcel: isExcel,
						img: isImg,
						certFile: isCert,
						videoFile: isVideo,
						// Naked Eyes
						hndImg: handImage,
						shdCd: shadeCard,
						hndVideo: isFinger,
						isTwz: isTweezer,
						hAFile: isHa,
						arrowFile: isArrow,
						assetFile: isAsset,
						pltFile: isPlt,
						mp4Video: isVideo2,
						type2: typeIIA,
						roughVideo: roughVideo,
						darkFieldImage: DarkField,
						flsImage: flourescence,
						idealWhiteImage: Ideal,
						roughImage: roughImage,
						planImg: DImage,
						faceUp: isFaceUp,
					};

					downloadZip(obj, (res) => {
						if (res) {
							props.clearAll();
							props.onCloseDownloadPopup();

							OpenNotification({
								type: "success",
								title: "Wait for sometime, your download is in process. File(s) will be downloaded soon.",
							});
						}
					});
				}
			}
			props.onCloseDownloadPopup();
		} else {
			OpenNotification({
				type: "error",
				title: "Please Select atleast 1 Option to Download.",
			});
		}
	};

	const updateAllImage = React.useCallback((check) => {
		setIsImg(check);
		setIsImg2(check);
		setIsPlt(check);
		setIsHa(check);
		setIsAsset(check);
		setIsFaceUp(check);
		setDarkField(check);
		setIdeal(check);
		setFlourescence(check);
		setIsArrow(check);
	}, []);

	const checkAllImages = React.useCallback(
		(allImages = null) => {
			if (_isBoolean(allImages)) {
				flagRef.current = flagRef.current + (allImages ? +2 : -2);
				return updateAllImage(allImages);
			}
			if (isImg && isImg2 && isPlt && isHa && isAsset && isArrow && isFaceUp && DarkField && Ideal && flourescence)
				setImages(true);
			else setImages(false);
		},
		[DarkField, Ideal, flourescence, isArrow, isAsset, isFaceUp, isHa, isImg, isImg2, isPlt, updateAllImage],
	);

	React.useEffect(() => {
		checkAllImages();
	}, [checkAllImages]);

	const updateAllVideo = React.useCallback((allVideo) => {
		setIsVideo(allVideo);
		setIsVideo2(allVideo);
	}, []);

	const checkAllVideo = React.useCallback(
		(allVideo = null) => {
			if (_isBoolean(allVideo)) {
				flagRef.current = flagRef.current + (allVideo ? +2 : -2);
				return updateAllVideo(allVideo);
			}
			if (isVideo && isVideo2) setVideos(true);
			else setVideos(false);
		},
		[isVideo, isVideo2, updateAllVideo],
	);

	React.useEffect(() => {
		checkAllVideo();
	}, [checkAllVideo]);

	/**
	 * Updates multiple state variables to a common value.
	 *
	 * @param {boolean} allNaked - The value to set for multiple state variables.
	 */
	const updateAllNaked = React.useCallback((allNaked) => {
		setHandImage(allNaked);
		setShadeCard(allNaked);
		setIsFinger(allNaked);
		setIsTweezer(allNaked);
	}, []);

	/**
	 * Checks if multiple states represent a "naked" condition and sets 'isNaked' accordingly.
	 *
	 * @param {boolean|null} allNaked - Optional parameter to set multiple states at once.
	 */
	const checkAllNacked = React.useCallback(
		(allNaked = null) => {
			if (_isBoolean(allNaked)) {
				// Update a flag based on the provided value
				flagRef.current = flagRef.current + (allNaked ? +2 : -2);
				return updateAllNaked(allNaked);
			}

			// Determine if all relevant states indicate a "naked" condition
			if (handImage && shadeCard && isFinger && isTweezer) {
				setIsNaked(true);
			} else {
				setIsNaked(false);
			}
		},
		[handImage, shadeCard, isFinger, isTweezer, updateAllNaked],
	);

	// Automatically check for the "naked" condition on component updates
	React.useEffect(() => {
		checkAllNacked();
	}, [checkAllNacked]);

	const updateAllCertificate = React.useCallback((allCertificate) => {
		setIsCert(allCertificate);
		setTypeIIA(allCertificate);
	}, []);

	const checkAllCertificate = React.useCallback(
		(allCertificate = null) => {
			if (_isBoolean(allCertificate)) {
				flagRef.current = flagRef.current + (allCertificate ? +2 : -2);
				return updateAllCertificate(allCertificate);
			}
			if (isCert && typeIIA) setCertificates(true);
			else setCertificates(false);
		},
		[isCert, typeIIA, updateAllCertificate],
	);

	React.useEffect(() => {
		checkAllCertificate();
	}, [checkAllCertificate]);

	const updateAllRough = React.useCallback((allRough) => {
		setRoughVideo(allRough);
		setRoughimage(allRough);
		setDImage(allRough);
	}, []);

	const checkAllRoughs = React.useCallback(
		(allRough = null) => {
			if (_isBoolean(allRough)) {
				flagRef.current = flagRef.current + (allRough ? +2 : -2);
				return updateAllRough(allRough);
			}
			if (roughImage && roughVideo && DImage) setRoughs(true);
			else setRoughs(false);
		},
		[DImage, roughImage, roughVideo, updateAllRough],
	);

	React.useEffect(() => {
		checkAllRoughs();
	}, [checkAllRoughs]);

	/**
	 * Handles the enabling or disabling of certain features based on the presence of checked diamonds.
	 *
	 * This effect checks if there are any diamonds selected (checked) and updates the state variables
	 * associated with various features accordingly.
	 */
	React.useEffect(() => {
		// Check if any diamonds are checked, and update the 'disableAll' state.
		setDisableAll(isEmpty(props?.checked));

		if (isEmpty(props?.checked)) {
			// If no diamonds are checked, reset various feature-related state variables.
			checkAllRoughs(false);
			checkAllCertificate(false);
			checkAllVideo(false);
			checkAllImages(false);
			checkAllNacked(false);
		}
	}, [props.checked, checkAllCertificate, checkAllImages, checkAllNacked, checkAllRoughs, checkAllVideo, ]);


	return (
		<div>
			<div className="searchPopupCommon">
				<div className="shareOptionSet d-flex">
					<div className="shareOptionSetItem">
						<ul>
							<div className="dropdownMainTitle">
								<div className="dropdownIconCheckBox">
									<Checkbox disabled={disableAll} checked={images} onChange={() => checkAllImages(!images)}></Checkbox>
								</div>
								<span>Images: </span>
							</div>
							<div className="dropdownRightCheck">
								<ShareOption title="B2B Image" checked={isImg} onClick={() => setIsImg((v) => !v)} />
								<ShareOption title="B2C Image" checked={isImg2} onClick={() => setIsImg2((v) => !v)} />
								<ShareOption title="Plotting" checked={isPlt} onClick={() => setIsPlt((v) => !v)} />
								<ShareOption title="Heart & Arrow" checked={isHa} onClick={() => setIsHa((v) => !v)} />
								<ShareOption title="Asset Scope" checked={isAsset} onClick={() => setIsAsset((v) => !v)} />
								<ShareOption title="Arrow Image" checked={isArrow} onClick={() => setIsArrow((v) => !v)} />
								<ShareOption title="Face Up Image" checked={isFaceUp} onClick={() => setIsFaceUp((v) => !v)} />
								<ShareOption title="Dark - Field Image" checked={DarkField} onClick={() => setDarkField((v) => !v)} />
								<ShareOption title="Ideal Scope Image" checked={Ideal} onClick={() => setIdeal((v) => !v)} />
								<ShareOption
									title="Flouresence Image"
									checked={flourescence}
									onClick={() => setFlourescence((v) => !v)}
								/>
							</div>
						</ul>
						<ul>
							<div className="width-50 d-flex align-items-center">
								<div className="dropdownMainTitle">
									<div className="dropdownIconCheckBox">
										<Checkbox disabled={disableAll} checked={videos} onChange={() => checkAllVideo(!videos)}></Checkbox>
									</div>
									<span>Videos: </span>
								</div>
								<div className="dropdownRightCheck">
									<ShareOption title="B2B Video" checked={isVideo} onClick={() => setIsVideo((v) => !v)} />
									<ShareOption title="B2C Video" checked={isVideo2} onClick={() => setIsVideo2((v) => !v)} />
								</div>
							</div>

							{/**
							* Renders a component for configuring and sharing options related to visual diamond inspection.
							*
							* @param {boolean} isNaked - Indicates whether all visual diamond inspection options are enabled.
							* @param {boolean} handImage - Indicates whether the hand image option is enabled.
							* @param {boolean} shadeCard - Indicates whether the shade card option is enabled.
							* @param {boolean} isFinger - Indicates whether the finger option is enabled.
							* @param {boolean} isTweezer - Indicates whether the tweezer option is enabled.
							* @param {boolean} disableAll - Indicates whether all options should be disabled.
							* @param {function} checkAllNacked - Callback function to toggle the "Naked Eyes" option.
							* @param {function} setHandImage - Callback function to toggle the "Hand Image" option.
							* @param {function} setShadeCard - Callback function to toggle the "Shade Card" option.
							* @param {function} setIsFinger - Callback function to toggle the "Finger" option.
							* @param {function} setIsTweezer - Callback function to toggle the "Tweezer" option.
							*/}
							<div className="width-50 d-flex align-items-center excelShare">
								<div className="dropdownMainTitle">
									<div className="dropdownIconCheckBox">
										<Checkbox disabled={disableAll} checked={isNaked} onChange={() => checkAllNacked(!isNaked)}></Checkbox>
									</div>
									<span>Naked Eyes: </span>
								</div>
								<div className="dropdownRightCheck">
									<ShareOption
										checked={handImage}
										onClick={() => setHandImage(!handImage)}
										title="Hand Image"
									/>
									<ShareOption
										checked={shadeCard}
										onClick={() => setShadeCard(!shadeCard)}
										title="Shade Card"
									/>
									<ShareOption
										checked={isFinger}
										onClick={() => setIsFinger(!isFinger)}
										title="Finger"
									/>
									<ShareOption
										checked={isTweezer}
										onClick={() => setIsTweezer(!isTweezer)}
										title="Tweezer"
									/>
								</div>
							</div>
						</ul>
						<ul className="d-flex">
							<div className="width-50 d-flex align-items-center">
								<div className="dropdownMainTitle">
									<div className="dropdownIconCheckBox">
										<Checkbox disabled={disableAll} checked={certificates} onChange={() => checkAllCertificate(!certificates)}></Checkbox>
									</div>
									<span>Certificates: </span>
								</div>
								<div className="dropdownRightCheck">
									<ShareOption title="Certificate" checked={isCert} onClick={() => setIsCert((v) => !v)} />
									<ShareOption title="Type IIA" checked={typeIIA} onClick={() => setTypeIIA((v) => !v)} />
								</div>
							</div>
							<div className="width-50 d-flex align-items-center excelShare">
								<div className="dropdownMainTitle">
									<span>Excel: </span>
								</div>
								<div className="dropdownRightCheck">
									<div>
										<ShareOption checked={isExcel} onClick={() => setIsExcel((v) => !v)} title="Excel" />
									</div>
								</div>
							</div>
						</ul>
						<ul>
							<div className="dropdownMainTitle">
								<div className="dropdownIconCheckBox">
									<Checkbox disabled={disableAll} checked={roughs} onChange={() => checkAllRoughs(!roughs)}></Checkbox>
								</div>
								<span>Roughs: </span>
							</div>
							<div className="dropdownRightCheck">
								<ShareOption title="Rough Scope" checked={roughImage} onClick={() => setRoughimage((v) => !v)} />
								<ShareOption title="3D Image" checked={DImage} onClick={() => setDImage((v) => !v)} />
								<ShareOption title="Rough Video" checked={roughVideo} onClick={() => setRoughVideo((v) => !v)} />
							</div>
						</ul>
					</div>
				</div>
				<div className="commonModalButton mt-50">
					<button className="fillButton" onClick={handleDownloadClick}>
						Download
					</button>
					<button onClick={props.onCloseDownloadPopup} className="outLineButton ml-5">
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
});

DownloadDropdown.displayName = "DownloadDropdown";

export default DownloadDropdown;
