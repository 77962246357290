export const BASE_URL =
	process.env.REACT_APP_ENV === "production"
		? "https://api.belgiumny.com"
		: "https://api.belgiumny.com";

export const DOWNLOAD_URL =
	process.env.REACT_APP_ENV === "production"
		? "https://download.belgiumny.com"
		: "https://download.belgiumny.com";

export const BASE_DOWNLOAD_URL = `${BASE_URL}/data`;
export const PRINT_URL = `${BASE_URL}/`;
export const MEDIA_URL = "https://diamondmedia.s3.amazonaws.com";

export const LOCAL_STORAGE_VAR = "fs-local";
export const VIEW_DNA_URL = "https://views.diadna.com";

export const LAB_LINKS = {
	IGI: "https://api.igi.org/viewpdf.php?r=***",
	GSI: "http://wg.gemscience.net/vr/veri.aspx",
	GIA: "https://www.gia.edu/report-check?reportno=***",
	HRD: "https://my.hrdantwerp.com/?record_number=***",
	TRCR: "https://search.tracr.com/***",
	XALL: "https://www.gia.edu/report-check?reportno=***",
};

export const ARTISAN_CERT_URL = "https://diamondmedia.s3.us-east-1.amazonaws.com/ARTISAN/ART_CERT/***.pdf";

export const FILE_URLS = {
	img: "https://diamondmedia.s3.amazonaws.com/B2B/imaged/***/still.jpg",
	certFile: "https://s3.ap-south-1.amazonaws.com/finestargroup/CertiImages/***.pdf",
	videoFile: "https://s3.ap-south-1.amazonaws.com/finestargroup/Mov/***.mp4",
	hAFile: "https://s3.ap-south-1.amazonaws.com/finestargroup/HeartImages/***.jpg",
	arrowFile: "https://s3.ap-south-1.amazonaws.com/finestargroup/ArowImages/***.jpg",
	assetFile: "https://s3.ap-south-1.amazonaws.com/finestargroup/AssetScopeImages/***.jpg",
};

export const PROJECT_SETTINGS_TYPE = {
	CM_CHARGES: 1,
	BANK_RATES: 2,
	SYNC_JOB_SETTINGS: 3,
	FM_CHARGES: 4,
	STOCK_STATUS: 5,
	MATCH_PAIR_COUNT: 6,
	CLIENT_EMAILS: 7,
	OFFICE_VIEW_ALLOWED: 8,
	NEW_ARRIVAL: 9,
	SP_ERROR_MESSAGES: 10,
	API_CALL_BLOCK_SETTING: 11,
	MATCH_PAIR_SIZE_GROUP: 12,
	QUOTE_VALIDATION: 13,
	BLOCK_VALIDATION: 14,
	CONFIRM_REQ_VALIDATION: 15,
	POP_UP_VALIDATION_MESSAGES: 16,
	DYNAMIC_FILENAME: 17,
	USER_BEHAVIOUR_EMAILS: 18,
	HOVER_TOOLTIP: 19,
	DIAMOND_STATUS_DESCRIPTION: 20,
	EXCLUDE_USER_EMAILS: 21,
	APPOINTMENT_EMAILS: 22,
	MEMO_EMAILS: 23,
	MEDIA_PATH: 24,
	VERSION_SETTING: 25,
	MEMO_SETTING_COUNTRY_WISE: 26,
	CART_ALLOW_STATUS: 27,
	WATCH_LIST_ALLOW_STATUS: 28,
	NOTE_ALLOW_STATUS: 29,
	BID_TIME_END_VALIDATION: 30,
	SHOW_EXTRA_DISCOUNT: 36,
};

export const DATA_DNA = {
	dnaDataID: true,
	id: "",
	stoneId: null, //id
	rptNo: "",
	certFile: false,
	videoFile: true,
	hAFile: true,
	pltFile: true,
	city: "",
	img: true,
	isFcCol: false,
	isPairSprbl: false,
	vStnId: "",
	mfgStnId: "",
	isSeal: false,
	ftc: true,
	prcStg: "",
	isXray: false,
	isDna: false,
	arrowFile: true,
	assetFile: true,
	sFlag: false,
	isSFm: false,
	videoFile2: false,
	diaImage: true,
	roughVideo: false,
	roughJVideo: false,
	flsImage: true,
	mp4Video: true,
	darkFieldImag: true,
	idealWhiteImag: true,
	roughImage: false,
	roughJImage: false,
	planImg: true,
	faceUp: true,
	b2cJsonVideo: true,
	b2cRealImage: true,
	gemPrint: true,
	smartI: false,
	altCert: [],
	isJrny: true,
	orgStnId: null, //id
	isAuction: false,
	artCtry: "",
	mfgLocation: "",
	hndImg: true,
	hndVideo: true,
	shdCd: true,
	isTwz: true,
};
