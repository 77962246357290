import find from "lodash/find";

import {
	SET_LOADER,
	SWITCH_LANGUAGE,
	PAGE_PERMISSION,
} from "../../constants/ActionTypes";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import languageData from "../../constants/languageData";

export function switchLanguage(language) {
	const locale = find(languageData, { languageId: language });
	localStorage.setItem(`${LOCAL_STORAGE_VAR}-localisation`, JSON.stringify(locale));
	return {
		type: SWITCH_LANGUAGE,
		payload: locale,
	};
}

export function setLoader(loading) {
	return {
		type: SET_LOADER,
		loading,
	};
}

export const PagePermission = (props) => {
	return (dispatch) => {
		dispatch({ type: PAGE_PERMISSION, payload: props });
	};
};
