import React, { useState, useEffect } from "react";

import "./diamondvideoview.less";
import split from "lodash/split";

import { getProjectSetting, isMobile } from "components/DiamondList/DiamondListFunctions";

import { SessionStore } from "services/Storage";

import { isEmpty } from "util/utils";

import { PROJECT_SETTINGS_TYPE } from "constants/Common";

import { LAB_LINKS, DATA_DNA } from "../../constants/Common";
import { VIEW_DIAMOND } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import MagnifierImage from "../DiamondDetail/MagnifierImage";

const DiamondVideoView = (props) => {
	const { tab } = props;
	const [mediaUrl, setMediaUrl] = useState(SessionStore.get("media-path-pattern") ?? {});
	const [data, setData] = useState({});

	const isMobileView = isMobile();

	const toggle = () => window.open(LAB_LINKS[data?.lbNm.toUpperCase()].replace("***", data?.rptNo));

	const getDiamondDetail = React.useCallback((id) => {
		const objData = {
			method: VIEW_DIAMOND.method,
			url: `${VIEW_DIAMOND.url}/${id}`,
			showLoader: true,
		};

		UtilService.callApi(objData, async (err, res) => {
			if (err && res && res?.data?.code === "E_NOT_FOUND") {
				let PacketRough = id;
				if (id.length >= 10) PacketRough = id.substring(0, 9) + "0";
				else {
					if (id.length < 8) PacketRough = id;
					else PacketRough = id.substring(0, 8) + "0";
				}
				DATA_DNA.stoneId = id;
				DATA_DNA.vStnId = id;
				DATA_DNA.orgStnId = id;
				DATA_DNA.mfgStnId = PacketRough;
				setData(DATA_DNA);
			} else {
				if (err) throw err;

				if (res && res.code === "OK") {
					setData(res?.data);
				}
			}
		});
	}, []);

	useEffect(() => {
		const id = split(window.location.pathname, "/")?.pop();
		id !== "diamondVideoview" && getDiamondDetail(id);
	}, [getDiamondDetail]);

	useEffect(() => {
		if (!isEmpty(mediaUrl)) return;

		// Create a flag to track mounting state
		let isMounted = true;

		getProjectSetting(PROJECT_SETTINGS_TYPE.MEDIA_PATH, (data) => {
			if (isMounted) {
				setMediaUrl(data?.MEDIA_PATH_PATTERN ?? {});
				SessionStore.set("media-path-pattern", data?.MEDIA_PATH_PATTERN);
			}
		});

		// Cleanup function to set the mounted flag to false when the component is unmounted
		return () => {
			isMounted = false;
		};
	}, [mediaUrl]);

	const getMediaUrl = (type) => {
		const url = mediaUrl?.[type]?.FILE_PATH;
		return data?.[url?.KEY] ? url?.PATH?.replaceAll("***", data?.[url?.KEY]) : null;
	};

	const handleDNA = () => window.open(`/dna/${data.vStnId}`);

	return (
		<div className="viewdiamond">
			{tab ? (
				<div className="container videos1">
					<div className="rows">
						{isMobileView ? (
							<>
								<div className="mobile">
									<div className="img-div">
										<h5>HAND</h5>
										{data?.hndImg && (
											<div className="box-shadow">
												<div className="imageSliderFullBlock">
													<MagnifierImage image={getMediaUrl("hndImg")}>
														<div className="no-msg">Image Coming Soon</div>;
													</MagnifierImage>
												</div>
											</div>
										)}
									</div>
									<div className="video-div">
										<h5>SHADE CARD</h5>
										{data?.shdCd && (
											<div className="box-shadow">
												<video controls autoPlay loop muted name="media">
													<source id="vidoshadecard" type="video/mp4" src={getMediaUrl("shdCd")} />
												</video>
											</div>
										)}
									</div>
								</div>
								<div className="mobile">
									<div className="video-div">
										<h5>FINGER</h5>
										{data?.hndVideo && (
											<div className="box-shadow">
												<video controls autoPlay loop muted name="media">
													<source
														id="vidoshadecard"
														type="video/mp4"
														src={getMediaUrl("hndVideo")}
													/>
												</video>
											</div>
										)}
									</div>
									<div className="video-div">
										<h5>TWEEZER</h5>
										{data?.isTwz && (
											<div className="box-shadow">
												<video controls autoPlay loop muted name="media">
													<source id="vidoshadecard" type="video/mp4" src={getMediaUrl("isTwz")} />
												</video>
											</div>
										)}
									</div>
								</div>
							</>
						) : (
							<>
								<div className="img-div">
									<h5>HAND</h5>
									{data?.hndImg && (
										<div className="box-shadow">
											<div className="imageSliderFullBlock">
												<MagnifierImage image={getMediaUrl("hndImg")}>
													<div className="no-msg">Image Coming Soon</div>;
												</MagnifierImage>
											</div>
										</div>
									)}
								</div>
								<div className="video-div">
									<h5>SHADE CARD</h5>
									{data?.shdCd && (
										<div className="box-shadow">
											<video controls autoPlay loop muted name="media">
												<source id="vidoshadecard" type="video/mp4" src={getMediaUrl("shdCd")} />
											</video>
										</div>
									)}
								</div>
								<div className="video-div">
									<h5>FINGER</h5>
									{data?.hndVideo && (
										<div className="box-shadow">
											<video controls autoPlay loop muted name="media">
												<source id="vidoshadecard" type="video/mp4" src={getMediaUrl("hndVideo")} />
											</video>
										</div>
									)}
								</div>
								<div className="video-div">
									<h5>TWEEZER</h5>
									{data?.isTwz && (
										<div className="box-shadow">
											<video controls autoPlay loop muted name="media">
												<source id="vidoshadecard" type="video/mp4" src={getMediaUrl("isTwz")} />
											</video>
										</div>
									)}
								</div>
							</>
						)}
					</div>
				</div>
			) : (
				<div className="container videos">
					<div className="row">
						{isMobileView ? (
							<>
								<div className="mobile">
									<div className="img-div">
										<h5>HAND</h5>
										{data?.hndImg && (
											<div className="box-shadow">
												<div className="imageSliderFullBlock">
													<MagnifierImage image={getMediaUrl("hndImg")}>
														<div className="no-msg">Image Coming Soon</div>;
													</MagnifierImage>
												</div>
											</div>
										)}
									</div>
									<div className="video-div">
										<h5>SHADE CARD</h5>
										{data?.shdCd && (
											<div className="box-shadow">
												<video controls autoPlay loop muted name="media">
													<source id="vidoshadecard" type="video/mp4" src={getMediaUrl("shdCd")} />
												</video>
											</div>
										)}
									</div>
								</div>
								<div className="mobile">
									<div className="video-div">
										<h5>FINGER</h5>
										{data?.hndVideo && (
											<div className="box-shadow">
												<video controls autoPlay loop muted name="media">
													<source
														id="vidoshadecard"
														type="video/mp4"
														src={getMediaUrl("hndVideo")}
													/>
												</video>
											</div>
										)}
									</div>
									<div className="video-div">
										<h5>TWEEZER</h5>
										{data?.isTwz && (
											<div className="box-shadow">
												<video controls autoPlay loop muted name="media">
													<source id="vidoshadecard" type="video/mp4" src={getMediaUrl("isTwz")} />
												</video>
											</div>
										)}
									</div>
								</div>
							</>
						) : (
							<>
								<div className="img-div">
									<h5>HAND</h5>
									{data?.hndImg && (
										<div className="box-shadow">
											<div className="imageSliderFullBlock">
												<MagnifierImage image={getMediaUrl("hndImg")}>
													<div className="no-msg">Image Coming Soon</div>;
												</MagnifierImage>
											</div>
										</div>
									)}
								</div>
								<div className="video-div">
									<h5>SHADE CARD</h5>
									{data?.shdCd && (
										<div className="box-shadow">
											<video controls autoPlay loop muted name="media">
												<source id="vidoshadecard" type="video/mp4" src={getMediaUrl("shdCd")} />
											</video>
										</div>
									)}
								</div>
								<div className="video-div">
									<h5>FINGER</h5>
									{data?.hndVideo && (
										<div className="box-shadow">
											<video controls autoPlay loop muted name="media">
												<source id="vidoshadecard" type="video/mp4" src={getMediaUrl("hndVideo")} />
											</video>
										</div>
									)}
								</div>
								<div className="video-div">
									<h5>TWEEZER</h5>
									{data?.isTwz && (
										<div className="box-shadow">
											<video controls autoPlay loop muted name="media">
												<source id="vidoshadecard" type="video/mp4" src={getMediaUrl("isTwz")} />
											</video>
										</div>
									)}
								</div>
							</>
						)}
					</div>
				</div>
			)}

			{isMobileView ? (
				<>
					{tab ? undefined : (
						<div className="dnaDetailBlock">
							<table className="table">
								<tbody>
									<tr>
										<th className="dnaImageTop" colSpan="14">
											<span>Report No : {data.WRAP_REPORT_NO}</span>
										</th>
									</tr>
									<tr>
										<th>Lab</th>
										<td>
											<span className="viewCerti">
												{" "}
												{data.lbNm}{" "}
											</span>
										</td>
										<th>Shape</th>
										<td>{data.shpNm}</td>
									</tr>
									<tr>
										<th>Carat</th>
										<td>{data.crt}</td>
										<th>Clarity</th>
										<td>{data.clrNm}</td>
									</tr>
									<tr>
										<th>Color</th>
										<td>{data.colNm}</td>
										<th>Cut</th>
										<td>{data.cutNm}</td>
									</tr>
									<tr>
										<th>Pol</th>
										<td>{data.polNm}</td>
										<th>Symm</th>
										<td>{data.symNm}</td>
									</tr>
									<tr>
										<th>Fls</th>
										<td>{data.fluNm}</td>
										<th>Depth%</th>
										<td>{data.depPer}</td>
									</tr>
									<tr>
										<th>Table%</th>
										<td>{data.tblPer}</td>
										<th>LWD</th>
										<td>{data.msrmnt}</td>
									</tr>
									<tr>
										<th>Ratio</th>
										<td>
											{data.ratio && data.ratio !== 0 ? parseFloat(data.ratio).toFixed(2) : "-"}
										</td>
										<th>DNA</th>
										<td>
											<span className="viewCerti" onClick={handleDNA}>
												{" "}
												DNA{" "}
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					)}
				</>
			) : (
				<>
					{tab ? undefined : (
						<div className="dnaDetailBlock">
							<table className="table">
								<thead>
									<tr>
										<th className="dnaImageTop" colSpan="14">
											<span>Report No : {data.WRAP_REPORT_NO}</span>
										</th>
									</tr>
									<tr>
										<th>Lab</th>
										<th>Shape</th>
										<th>Carat</th>
										<th>Clarity</th>
										<th>Color</th>
										<th>Cut</th>
										<th>Pol</th>
										<th>Symm</th>
										<th>Fls</th>
										<th>Depth%</th>
										<th>Table%</th>
										<th>LWD</th>
										<th>Ratio</th>
										<th>DNA</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className="viewCerti">
												{" "}
												{data.lbNm}{" "}
											</span>
										</td>
										<td>{data.shpNm}</td>
										<td>{data.crt}</td>
										<td>{data.clrNm}</td>
										<td>{data.colNm}</td>
										<td>{data.cutNm}</td>
										<td>{data.polNm}</td>
										<td>{data.symNm}</td>
										<td>{data.fluNm}</td>
										<td>{data.depPer}</td>
										<td>{data.tblPer}</td>
										<td>{data.msrmnt}</td>
										<td>
											{data.ratio && data.ratio !== 0 ? parseFloat(data.ratio).toFixed(2) : "-"}
										</td>
										<td>
											<span className="viewCerti" onClick={handleDNA}>
												{" "}
												DNA{" "}
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					)}
				</>
			)}
		</div>
	);
};
export default DiamondVideoView;
