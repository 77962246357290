import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import MagnifierImage from "../DiamondDetail/MagnifierImage";
import { getMediaUrl } from "services/Commonfunction";
import OpenNotification from "components/common/CommonButton/OpenNotification";

const ImageBlock = (props) => {
	const slider1 = useRef(null);

	const { dnaData, setCurrentIndex, currentIndex } = props;
	const [imgUrl, setImgUrl] = useState("");

	const isMountedRef = useRef(true); // useRef to track mounting state

	useEffect(() => {
		// Set the flag to true when mounted
		isMountedRef.current = true;

		// Cleanup function to set the flag to false when unmounted
		return () => {
			isMountedRef.current = false;
		};
	}, []);

	useEffect(() => {
		let isMounted = true; // Flag to track mounting state

		async function fetchData() {
			try {
				const url = await getMediaUrl("img", dnaData);

				// Check if the component is still mounted before updating the state
				if (isMounted) {
					setImgUrl(url);
				}
			} catch (error) {
				OpenNotification({
					type: "error",
					title: error.message,
				});
			}
		}

		// Call the asynchronous function
		fetchData();

		// Cleanup function to set the mounted flag to false when the component is unmounted
		return () => {
			isMounted = false;
		};
	}, [dnaData]);

	useEffect(() => {
		slider1.current.slickGoTo(currentIndex);
	}, [currentIndex]);

	return (
		<>
			<div>
				<Slider
					className="sliderMainImage sliderTwoImageBlock"
					arrows={false}
					ref={slider1}
					beforeChange={(_current, next) => setCurrentIndex(next)}
				>
					{dnaData?.img ? (
						<div className="imageSliderFullBlock">
							<MagnifierImage image={imgUrl}>
								<div className="no-msg">Image Coming Soon</div>
							</MagnifierImage>
						</div>
					) : (
						<div className="imageSliderFullBlock no-msg">Image Coming Soon</div>
					)}
				</Slider>
			</div>
		</>
	);
};

export default ImageBlock;
