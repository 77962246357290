import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Router, Route, Switch, useLocation } from "react-router-dom";

import DNA from "components/DNA";
import ViewImage from "components/DNA/ViewImage";
import DiamondFullView from "components/DiamondFullView";
import DiamondVideoView from "components/DiamondVideoView";
import PageNotFound from "components/PageNotFound";
import SignIn from "components/SignIn";
import CompareDna from "components/DNA/CompareDna";

import { LocalStore } from "services/Storage";

import { history } from "util/history";
import { isEmpty, isFunction } from "util/utils";

const SignInRoutes = React.memo(({ component: Component, render, ...restProps }) => {
	const token = useSelector((state) => state.auth.token);
	const location = useLocation();

	return (
		<Route
			{...restProps}
			render={(props) => {
				if (isEmpty(token)) {
					LocalStore.set("last", location.pathname);
					return <Redirect to="/" />;
				}
				if (isFunction(render)) return render(props);
				return <Component {...props} />;
			}}
		/>
	);
});
SignInRoutes.displayName = "SignInRoutes";

const SignOutRoutes = React.memo(({ component: Component, render, ...restProps }) => {
	const token = useSelector((state) => state.auth.token);

	return (
		<Route
			{...restProps}
			render={(props) => {
				if (!isEmpty(token)) return <Redirect to="/" />;
				if (isFunction(render)) return render(props);
				return <Component {...props} />;
			}}
		/>
	);
});
SignOutRoutes.displayName = "SignOutRoutes";

function App() {
	const token = useSelector((state) => state.auth.token);

	React.useEffect(() => {
		if (!window.drift) return;
		window.drift.on("campaign:dismiss", () => {});
		if (window.drift && window.drift.api) window.drift.api.widget.hide();
	}, []);

	return (
		<Router history={history}>
			<Switch>
				{isEmpty(token) ? (
					<Redirect exact from="/" to="signin" />
				) : (
					<Redirect exact from="/" to="dna" />
				)}
				<Route path="/not-found" component={PageNotFound} />
				<SignOutRoutes path="/signin" component={SignIn} />
				<Route path="/dna/compare-dna" component={CompareDna} />
				<Route path="/dna" component={DNA} />
				<Route path="/viewImage" component={ViewImage} />
				<Route path="/diamondfullview" component={DiamondFullView} />
				<Route path="/diamondVideoview" component={DiamondVideoView} />
				<SignInRoutes path="/" component={DNA} />
			</Switch>
		</Router>
	);
}

export default React.memo(App);
