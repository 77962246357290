import React, { useState } from "react";
import Slider from "react-slick";

import MagnifierImage from "../DiamondDetail/MagnifierImage";
import Image from "../common/Image";

const ImageSlider = (props) => {
	const { dnaData: mediaUrls, getMediaUrl: getMediaUrls } = props;

	const [nav1, setNav1] = useState();
	const [nav2, setNav2] = useState();

	const slidesToShow = React.useMemo(() => {
		return [
			mediaUrls.img ? 4 : 0,
			mediaUrls.hAFile ? 1 : 0,
			mediaUrls.arrowFile ? 1 : 0,
			mediaUrls.assetFile ? 1 : 0,
			mediaUrls.fluNm && mediaUrls.fluNm !== "NON" ? 1 : 0,
		].reduce((a, b) => a + b, 0);
	}, [mediaUrls.arrowFile, mediaUrls.assetFile, mediaUrls.fluNm, mediaUrls.hAFile, mediaUrls.img]);

	const setting = React.useMemo(() => {
		return [
			{ breakpoint: 1400, settings: { slidesToShow } },
			{ breakpoint: 991, settings: { slidesToShow: slidesToShow < 4 ? slidesToShow : 4 } },
			{ breakpoint: 767, settings: { slidesToShow: slidesToShow < 2 ? slidesToShow : 2 } },
		];
	}, [slidesToShow]);

	return (
		<div className="dnaLeftBox dnaLeftBox-m-h">
			<div className="dnaLeftBoxImage diamond-Image">
				<div className="dnaImageTop">
					<span>Diamond Image</span>
				</div>
				<Slider className="sliderMainImage" asNavFor={nav2} ref={setNav1} arrows adaptiveHeight>
					{mediaUrls.img && (
						<div
							className="imageSliderFullBlock"
							onClick={() => {
								window.open(`/viewImage/Office_Light_Black_BG/${mediaUrls.id}`);
							}}
						>
							<MagnifierImage image={getMediaUrls("img")} />
						</div>
					)}
					{mediaUrls.hAFile && (
						<div
							className="imageSliderFullBlock"
							onClick={() => {
								window.open(`/viewImage/HeartImages/${mediaUrls.id}`);
							}}
						>
							<MagnifierImage image={getMediaUrls("hAFile")} />
						</div>
					)}
					{mediaUrls.arrowFile && (
						<div
							className="imageSliderFullBlock"
							onClick={() => {
								window.open(`/viewImage/ArrowImages/${mediaUrls.id}`);
							}}
						>
							<MagnifierImage image={getMediaUrls("arrowFile")} />
						</div>
					)}
					{mediaUrls.assetFile && (
						<div
							className="imageSliderFullBlock"
							onClick={() => {
								window.open(`/viewImage/AssetScopeImages/${mediaUrls.id}`);
							}}
						>
							<MagnifierImage image={getMediaUrls("assetFile")} />
						</div>
					)}
					{/* {dnaData.pltFile ? ( */}
					{mediaUrls.img && (
						<div
							className="imageSliderFullBlock"
							onClick={() => {
								window.open(`/viewImage/PlottingImages/${mediaUrls.id}`);
							}}
						>
							<MagnifierImage image={getMediaUrls("pltFile")} />
						</div>
					)}
					{mediaUrls?.digitalcard && (
						<div
							className="imageSliderFullBlock"
							onClick={() => {
								window.open(`/viewImage/SymmetryImages/${mediaUrls.id}`);
							}}
						>
							<MagnifierImage image={getMediaUrls("digitalcard")} />
						</div>
					)}
					{mediaUrls.img && (
						<div
							className="imageSliderFullBlock"
							onClick={() => {
								window.open(`/viewImage/Dark_Field_White_BG/${mediaUrls.id}`);
							}}
						>
							<MagnifierImage image={getMediaUrls("darkFieldImage")} />
						</div>
					)}
					{mediaUrls.img && (
						<div
							className="imageSliderFullBlock"
							onClick={() => {
								window.open(`/viewImage/IDEAL_White_BG/${mediaUrls.id}`);
							}}
						>
							<MagnifierImage image={getMediaUrls("idealWhiteImage")} />
						</div>
					)}
					{mediaUrls.fluNm && mediaUrls.fluNm !== "NON" && (
						<div
							className="imageSliderFullBlock"
							onClick={() => {
								window.open(`/viewImage/FlsImages/${mediaUrls.id}`);
							}}
						>
							<MagnifierImage image={getMediaUrls("flsImage")} />
						</div>
					)}
				</Slider>
				<Slider
					className="sliderSmallImage"
					asNavFor={nav1}
					ref={setNav2}
					slidesToShow={slidesToShow}
					slidesToScroll={1}
					responsive={setting}
					arrows={false}
					focusOnSelect
					swipeToSlide
					infinite
				>
					{mediaUrls.img && (
						<div className="imageSliderSmallBlock">
							<div className="imageSliderSmallBox">
								<Image src={getMediaUrls("img")} />
							</div>
							<span className="imageVideoTitle">Face-Up</span>
						</div>
					)}

					{mediaUrls.hAFile && (
						<div className="imageSliderSmallBlock">
							<div className="imageSliderSmallBox">
								<Image src={getMediaUrls("hAFile")} />
							</div>
							<span className="imageVideoTitle">Heart</span>
						</div>
					)}

					{mediaUrls.arrowFile && (
						<div className="imageSliderSmallBlock">
							<div className="imageSliderSmallBox">
								<Image src={getMediaUrls("arrowFile")} />
							</div>
							<span className="imageVideoTitle">Arrow</span>
						</div>
					)}

					{mediaUrls.assetFile && (
						<div className="imageSliderSmallBlock">
							<div className="imageSliderSmallBox">
								<Image src={getMediaUrls("assetFile")} />
							</div>
							<span className="imageVideoTitle">Asset</span>
						</div>
					)}

					{mediaUrls.img && (
						<div className="imageSliderSmallBlock">
							<div className="imageSliderSmallBox">
								<Image src={getMediaUrls("pltFile")} />
							</div>
							<span className="imageVideoTitle">Plotting</span>
						</div>
					)}

					{mediaUrls?.digitalcard && (
						<div className="imageSliderSmallBlock">
							<div className="imageSliderSmallBox">
								<Image src={getMediaUrls("digitalcard")} />
							</div>
							<span className="imageVideoTitle">Symmetry</span>
						</div>
					)}

					{mediaUrls.img && (
						<div className="imageSliderSmallBlock">
							<div className="imageSliderSmallBox">
								<Image src={getMediaUrls("darkFieldImage")} />
							</div>
							<span className="imageVideoTitle">Dark-Field</span>
						</div>
					)}

					{mediaUrls.img && (
						<div className="imageSliderSmallBlock">
							<div className="imageSliderSmallBox">
								<Image src={getMediaUrls("idealWhiteImage")} />
							</div>
							<span className="imageVideoTitle">Ideal Scope</span>
						</div>
					)}

					{mediaUrls.fluNm && mediaUrls.fluNm !== "NON" && (
						<div className="imageSliderSmallBlock">
							<div className="imageSliderSmallBox">
								<Image src={getMediaUrls("flsImage")} />
							</div>
							<span className="imageVideoTitle">Fluorescence </span>
						</div>
					)}
				</Slider>
			</div>
		</div>
	);
};

export default ImageSlider;
