import React from "react";

import noImageSvg from "../../assets/svg/noimage.svg";
import MagnifierImage from "../DiamondDetail/MagnifierImage";

const DiamondJourney = (props) => {
	const { dnaData, getMediaUrl } = props;
	return (
		<div className="diamondJourneyBlock">
			{dnaData.roughImage ? (
				<div className="diamondJourneyItem">
					<div className="diamondJourneyTitle">
						<span>Rough Image</span>
					</div>
					<div
						className="diamondJourneyImage"
						onClick={() => {
							window.open(`/viewImage/RoughImages/${dnaData.id}`);
						}}
					>
						<MagnifierImage image={getMediaUrl("roughImage")} />
					</div>
				</div>
			) : dnaData.roughJImage ? (
				<div className="diamondJourneyItem">
					<div className="diamondJourneyTitle">
						<span>Rough Image</span>
					</div>
					<div
						className="diamondJourneyImage"
						onClick={() => {
							window.open(`/viewImage/RoughImages/${dnaData.id}`);
						}}
					>
						<MagnifierImage image={getMediaUrl("roughJImage")} />
					</div>
				</div>
			) : dnaData?.dnaDataID ? (
				<div className="diamondJourneyItem">
					<div className="diamondJourneyTitle">
						<span>Rough Image</span>
					</div>
					<div
						className="diamondJourneyImage"
						onClick={() => {
							window.open(`/viewImage/RoughImages/${dnaData.id}`);
						}}
					>
						<MagnifierImage image={getMediaUrl("roughJImage")} />
					</div>
				</div>
			) : (
				<div className="diamondJourneyItem">
					<div className="diamondJourneyTitle">
						<span>Rough Image</span>
					</div>
					<div className="diamondJourneyImage" style={{ overflowY: "hidden" }}>
						<img {...props} src={noImageSvg} alt="" style={props?.style ?? {}} />
					</div>
				</div>
			)}

			{dnaData.roughVideo ? (
				<div className="diamondJourneyItem">
					<div className="diamondJourneyTitle">
						<span>Rough Video</span>
					</div>
					<div className="diamondJourneyImage" style={{ overflowY: "hidden" }}>
						<iframe
							style={{ width: "83%", height: "100%", minHeight: "241px" }}
							src={getMediaUrl("roughVideo")}
							scrolling="no"
							title="rough Video"
						/>
					</div>
				</div>
			) : dnaData.roughJVideo ? (
				<div className="diamondJourneyItem">
					<div className="diamondJourneyTitle">
						<span>Rough Video</span>
					</div>
					<div className="diamondJourneyImage" style={{ overflowY: "hidden" }}>
						<iframe
							style={{ width: "83%", height: "100%", minHeight: "241px" }}
							src={getMediaUrl("roughJVideo")}
							scrolling="no"
							title="rough Video1"
						/>
					</div>
				</div>
			) : dnaData?.dnaDataID ? (
				<div className="diamondJourneyItem">
					<div className="diamondJourneyTitle">
						<span>Rough Video</span>
					</div>
					<div className="diamondJourneyImage" style={{ overflowY: "hidden" }}>
						<iframe
							style={{ width: "83%", height: "100%", minHeight: "241px" }}
							src={getMediaUrl("roughJVideo")}
							scrolling="no"
							title="roughJ Video"
						/>
					</div>
				</div>
			) : (
				<div className="diamondJourneyItem">
					<div className="diamondJourneyTitle">
						<span>Rough Video</span>
					</div>
					<div className="diamondJourneyImage" style={{ overflowY: "hidden" }}>
						<img {...props} src={noImageSvg} alt="" style={props?.style ?? {}} />
					</div>
				</div>
			)}

			{dnaData.img && (
				<div className="diamondJourneyItem">
					<div className="diamondJourneyTitle">
						<span>3D Image</span>
					</div>
					<div
						className="diamondJourneyImage"
						onClick={() => {
							window.open(`/viewImage/PlanImages/${dnaData.id}`);
						}}
					>
						<MagnifierImage image={getMediaUrl("planImg")} />
					</div>
				</div>
			)}
			{dnaData.img && (
				<div className="diamondJourneyItem">
					<div className="diamondJourneyTitle">
						<span>B2B Image</span>
					</div>
					<div
						className="diamondJourneyImage"
						onClick={() => {
							window.open(`/viewImage/RealImages/${dnaData.id}`);
						}}
					>
						<MagnifierImage image={getMediaUrl("img")} />
					</div>
				</div>
			)}
		</div>
	);
};
export default DiamondJourney;
