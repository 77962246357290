import React, { useState, useEffect } from "react";

import "./dna.less";
import _ from "lodash";
import QRCode from "qrcode.react";

import OpenNotification from "components/common/CommonButton/OpenNotification";

import { SessionStore } from "services/Storage";
import UtilService from "services/util";

import { isArray, isEmpty, isNotEmpty } from "util/utils";

import {
	LOCAL_STORAGE_VAR,
	PROJECT_SETTINGS_TYPE,
	ARTISAN_CERT_URL,
	DATA_DNA,
} from "constants/Common";
import { VIEW_DIAMOND, DIAMOND_DATA_BY_ID } from "constants/apiConstant";

import DNADetail from "./DNADetail";
import DiamondJourney from "./DiamondJourney";
import ImageBlock from "./ImageBlock";
import ImageSlider from "./ImageSlider";
import VideoBlock from "./VideoBlock";

import { getProjectSetting, isMobile } from "../DiamondList/DiamondListFunctions";
import CommonModal from "../common/CommonModal";
import DownloadDropdown from "../common/DiamondListing/DownloadDropdown";

import ShareDropdown from "../common/DiamondListing/ShareDropdown";
import Image from "../common/Image";
import Loader from "../common/Loader";

import NoDataShow from "components/common/NoDataShow";
import DiamondVideoView from "components/DiamondVideoView";
import MobileShareOption from "components/common/DiamondListing/MobileShareOption";
import MobileDownloadOption from "components/common/DiamondListing/MobileDownloadOption";

import "../common/Tab";

const DNA = () => {
	const [share, setShare] = useState(false);
	const [share1, setShare1] = useState(false);
	const [dnaData, setDnaData] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [actualData, setActualData] = useState({});
	const [tabFlag, setTabFlag] = useState(-1);
	const [mediaUrl, setMediaUrl] = useState(SessionStore.get("media-path-pattern") ?? {});
	const [notFound, setNotFound] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);

	const [showIcon, setShowIcon] = useState(false);
	const [tabSelectionValue, setTabSelectionValue] = useState("GIA");

	const isMobileView = isMobile();

	// This effect is responsible for handling scroll events and toggling the icon based on scroll position.
	useEffect(() => {
		/**
		 * Function to handle scroll
		 *
		 * @function
		 * @name handleScroll
		 */
		const handleScroll = () => {
			// Check if scroll position is greater than 0 to determine whether to show the icon
			setShowIcon(window.scrollY > 0);
		};

		// Listen for scroll events
		window.addEventListener("scroll", handleScroll);

		// Clean up the event listener when the component is unmounted to prevent memory leaks
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const shareClick = () => {
		setShare(true);
	};

	const shareClick1 = () => {
		setShare1(true);
	};

	const onClose = () => {
		setShare(false);
		setShare1(false);
	};

	const handleHitCounter = (id) => {
		const objData = { ...DIAMOND_DATA_BY_ID, request: { isHitCounter: true, id: id?.id } };
		UtilService.callApi(objData, () => {});
	};

	const getDiamondDetail = React.useCallback(async () => {
		const id = _.last(_.split(window.location.pathname, "/"));

		if (id !== "dna") {
			const api = `${VIEW_DIAMOND.url}/${id}`;
			const objData = {
				method: VIEW_DIAMOND.method,
				url: api,
				showLoader: true,
			};
			setIsLoading(true);

			UtilService.callApi(objData, async (err, data) => {
				if (data?.data?.code === "E_NOT_FOUND") {
					setNotFound(data?.data?.code);
				} else if (err) {
					setIsLoading(false);

					if (data?.status || data?.data?.status === 502) {
						return OpenNotification({
							type: "error",
							title: "Something went wrong, Please try again.",
						});
					} else {
						return OpenNotification({
							type: "error",
							title: data.data.message ?? "Something went wrong, Please try again.",
						});
					}
				}

				if (data && data.code === "OK") {
					setActualData(data?.data);
					setDnaData(data?.data);
					handleHitCounter(data?.data);
				} else if (data?.data?.code === "E_NOT_FOUND") {
					let PacketRough = id;
					if (id.length >= 10) PacketRough = id.substring(0, 9) + "0";
					else {
						if (id.length < 8) PacketRough = id;
						else PacketRough = id.substring(0, 8) + "0";
					}
					DATA_DNA.stoneId = id;
					DATA_DNA.vStnId = id;
					DATA_DNA.orgStnId = id;
					DATA_DNA.mfgStnId = PacketRough;

					setActualData(DATA_DNA);
					setDnaData(DATA_DNA);
				}
				setIsLoading(false);
			});
		}
	}, []);

	const handleLoader = (loading) => {
		loading
			? document.body.classList.add("loading-indicator")
			: document.body.classList.remove("loading-indicator");
	};

	useEffect(() => {
		// get diamond detail if media setting exist
		if (!isEmpty(mediaUrl)) return getDiamondDetail();

		getProjectSetting(PROJECT_SETTINGS_TYPE.MEDIA_PATH, (data) => {
			setMediaUrl(data?.MEDIA_PATH_PATTERN ?? {});
			SessionStore.set("media-path-pattern", data?.MEDIA_PATH_PATTERN);
			getDiamondDetail();
		});
	}, [getDiamondDetail, mediaUrl]);

	const getMediaUrl = React.useCallback(
		(type) => {
			if (type === "videoFile") {
				const colorNm = [
					"D",
					"E",
					"F",
					"G",
					"H",
					"I",
					"J",
					"K",
					"L",
					"M",
					"N",
					"O",
					"P",
					"Q",
					"R",
					"S",
					"T",
					"U",
					"V",
					"W",
					"X",
					"Y",
					"Z",
					"OP",
					"QR",
					"ST",
					"UV",
					"WX",
					"O-P",
					"Q-R",
					"S-T",
					"U-V",
					"W-X",
				];
				if (notFound === "E_NOT_FOUND" || _.includes(colorNm, dnaData.colNm)) {
					const url = mediaUrl?.[type]?.FILE_PATH;
					return url?.PATH?.replaceAll("***", dnaData?.[url?.KEY]);
				} else {
					if (!_.includes(mediaUrl?.[type]?.FILE_PATH?.PATH, "&sv=5,4,3,2,1")) {
						const parameter = mediaUrl?.[type]?.FILE_PATH;
						parameter.PATH = parameter.PATH + "&sv=5,4,3,2,1";
						const url = parameter;
						return url?.PATH?.replaceAll("***", dnaData?.[url?.KEY]);
					} else {
						const url = mediaUrl?.[type]?.FILE_PATH;
						return url?.PATH?.replaceAll("***", dnaData?.[url?.KEY]);
					}
				}
			} else {
				if (tabSelectionValue === "AGS" && type === "certFile") {
					const url = mediaUrl?.[type]?.FILE_PATH;
					return url?.PATH?.replaceAll("***", dnaData?.["AGS_REPORT_NO"]);
				} else {
					const url = mediaUrl?.[type]?.FILE_PATH;
					return url?.PATH?.replaceAll("***", dnaData?.[url?.KEY]);
				}
			}
		},
		[dnaData, mediaUrl, notFound, tabSelectionValue],
	);

	/**
	 * Scrolls smoothly to the top of the page.
	 *
	 * @function
	 * @name scrollToTop
	 */
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const isGuest = localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);
	return isLoading ? (
		<Loader loading={isLoading} />
	) : actualData?.hideDna !== "DNA" ? (
		<>
			{/* SCROLL TO TOP */}
			{showIcon && (
				<button className="top-arrow" onClick={scrollToTop}>
					<img src={require("../../assets/svg/top-arrow.svg")} alt="Scroll To Top" />
				</button>
			)}
			{/* QR CODE */}
			<div className="qrCodeOption">
				<QRCode size={50} value={window.location.href} level={"L"} />
				<span>QR Code</span>
			</div>
			<div className="dnaImageTop mainBox d-flex">
				{
					<span>
						{dnaData?.length
							? [
									dnaData?.vStnId,
									dnaData?.shpNm,
									dnaData?.crt,
									dnaData?.colNm,
									dnaData?.clrNm,
									dnaData?.cutNm,
									dnaData?.polNm,
									dnaData?.symNm,
									dnaData?.fluNm,
								]
									?.filter(isNotEmpty)
									?.join(" ")
							: "-"}
					</span>
				}
				{!isGuest && (
					<div className="dnaDetailShareOption">
						<div className="dnaShareOption" onClick={shareClick}>
							<Image src={require("assets/svg/DNA/share-theme.svg")} alt="" />
							<span>Share</span>
						</div>
						<div className="dnaShareOption" onClick={shareClick1}>
							<Image src={require("assets/svg/DNA/download.svg")} alt="" />
							<span>Download</span>
						</div>
					</div>
				)}
			</div>
			<div className={`dnaContainerBox  ${isLoading ? `loading-indicator` : null}`}>
				{actualData && (
					<div className="customerSubTab diamondlistTab">
						<ul className="accountTabBox">
							<li className={tabFlag === -1 ? "react-tabs__tab--selected" : null}>
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
								<a
									onClick={(e) => {
										setDnaData(actualData);
										setTabFlag(-1);
										setTabSelectionValue(e.currentTarget.firstChild.textContent);
									}}
								>
									<span>{actualData?.lbNm}</span>
								</a>
							</li>
							{isArray(actualData?.altCert) &&
								isNotEmpty(actualData?.altCert) &&
								actualData.altCert.map((values, index) => (
									<li
										key={values?.lbNm}
										className={tabFlag === index ? "react-tabs__tab--selected" : null}
									>
										{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
										<a
											onClick={(e) => {
												setDnaData({ ...dnaData, ...values });
												setTabFlag(index);
												setTabSelectionValue(e.currentTarget.firstChild.textContent);
											}}
										>
											<span>{values?.lbNm}</span>
										</a>
									</li>
								))}

							{isNotEmpty(actualData) && actualData?.hndVideo ? (
								<React.Fragment>
									<li className={tabFlag === 3 ? "react-tabs__tab--selected" : null}>
										{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
										<a
											onClick={() => {
												setTabFlag(3);
											}}
										>
											<span>Naked Eye</span>
										</a>
									</li>
								</React.Fragment>
							) : null}

							{isNotEmpty(actualData) && actualData?.isArtisanCerti === true && (
								<li className={tabFlag === 2 ? "react-tabs__tab--selected" : null}>
									{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
									<a
										onClick={() => {
											setTabFlag(2);
										}}
									>
										<span>Meet The Artisan</span>
									</a>
								</li>
							)}
						</ul>
					</div>
				)}

				{tabFlag === -1 || tabFlag === 0 ? (
					<>
						<div className="dnaWrapper d-flex width-100">
							<div className="dnaWrapperBox threeBlock1">
								<div className="dnaImageBlock">
									<div className="dnaImageTop">
										<span>Video</span>
									</div>
									<div className="dnaImageSwipe">
										<VideoBlock
											className="video-m"
											dnaData={dnaData}
											currentIndex={currentIndex}
											setCurrentIndex={setCurrentIndex}
										/>
									</div>
								</div>
							</div>
							<div className="dnaWrapperBox threeBlock1">
								<div className="dnaImageBlock">
									<div className="dnaImageTop">
										<span>Image</span>
									</div>
									<div className="dnaImageSwipe">
										<ImageBlock
											className="img-m"
											dnaData={dnaData}
											currentIndex={currentIndex}
											setCurrentIndex={setCurrentIndex}
										/>
									</div>
								</div>
							</div>
						</div>

						{(actualData?.hndVideo === true) ? (
							<React.Fragment>
								<DiamondVideoView tab={tabFlag === -1 || tabFlag === 0} />
							</React.Fragment>
						) : null}

						{actualData?.hideDna !== "DETAILS" && (
							<div className="dnaWrapperBox">
								<div className="dnaImageTop">
									<span>Diamond Details</span>
								</div>
								<div className="dnaLeftRightDetil d-flex flex-wrap">
									<DNADetail dnaData={dnaData} />
								</div>
							</div>
						)}
						<div className="dnaWrapper d-flex">
							<div className="dnaWrapperBox">
								<div className="dnaImageBlock">
									<div className="dnaImageSwipe">
										<ImageSlider dnaData={dnaData} getMediaUrl={getMediaUrl} />
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="dnaJourenySticky diamond-journey">
								<div className="dnaImageTop">
									<span>Diamond Journey</span>
								</div>
								<DiamondJourney dnaData={dnaData} getMediaUrl={getMediaUrl} />
							</div>
						</div>
						{dnaData.isJrny && (
							<div>
								<div className="dnaJourenySticky video-m">
									<div className="dnaImageTop">
										<span>Journey</span>
									</div>
									<video width="100%" height="624px;" id="vid" controls loop autoPlay muted>
										<source src={getMediaUrl("isJrny")} type="video/mp4" />
									</video>
								</div>
							</div>
						)}
						<div className={`dnaContainerBox main-box ${isLoading && `loading-indicator`}`}>
							{actualData.altCert && (
								<div className="customerSubTab diamondlistTab">
									<ul className="accountTabBox">
										<li className={tabFlag === -1 && "react-tabs__tab--selected"}>
											{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
											<a
												onClick={(e) => {
													setDnaData(actualData);
													setTabFlag(-1);
													setTabSelectionValue(e.currentTarget.firstChild.textContent);
												}}
											>
												<span>{actualData.lbNm}</span>
											</a>
										</li>
										{isNotEmpty(actualData.altCert) &&
											isArray(actualData.altCert) &&
											actualData.altCert.map((values, index) => (
												<li
													key={values.lbNm}
													className={tabFlag === index && "react-tabs__tab--selected"}
												>
													{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
													<a
														key={values.lbNm}
														onClick={(e) => {
															setDnaData({ ...dnaData, ...values });
															setTabFlag(index);
															setTabSelectionValue(e.currentTarget.firstChild.textContent);
														}}
													>
														<span>{values.lbNm}</span>
													</a>
												</li>
											))}
									</ul>
								</div>
							)}
						</div>
						{dnaData.certFile && (
							<div>
								<div className="dnaJourenySticky">
									<div className="dnaImageTop">
										<span>Certificate</span>
									</div>
									<iframe
										title="cert File"
										src={getMediaUrl("certFile")}
										alt=""
										style={{ height: "900px", width: "100%" }}
									/>
								</div>
							</div>
						)}
						{dnaData.type2 && !isEmpty(dnaData.type2) && (
							<div>
								<div className="dnaJourenySticky">
									<div className="dnaImageTop">
										<span>Certificate</span>
									</div>
									<iframe title="type II" width="100%" height="900px" src={getMediaUrl("type2")} />
								</div>
							</div>
						)}
					</>
				) : null}

				{tabFlag === 2 ? (
					<div>
						<div className="dnaJourenySticky">
							<div className="dnaImageTop">
								<span>The Artisan certificate</span>
							</div>
							<iframe
								title="ARTISAN CERT URL"
								src={ARTISAN_CERT_URL?.replaceAll("***", dnaData?.vStnId)}
								alt=""
								style={{ height: "900px", width: "100%" }}
							/>
						</div>
					</div>
				) : null}
			</div>

			{tabFlag === 3 ? (
				<React.Fragment>
					<DiamondVideoView />
				</React.Fragment>
			) : null}

			{share && (
				<CommonModal
					modalSize="ListingModal"
					title="Share Stone"
					handleCancel={onClose}
					visible={share}
					wrapClassName=""
				>
					{!isMobileView ? (
						<ShareDropdown checked={[dnaData]} onCloseSharePopup={onClose} />
					) : (
						<MobileShareOption checked={[dnaData]} onClose={onClose} />
					)}
				</CommonModal>
			)}
			{share1 && (
				<CommonModal
					modalSize="ListingModal"
					title="Download Stone"
					handleCancel={onClose}
					visible={share1}
					wrapClassName=""
				>
					{!isMobileView ? (
						<DownloadDropdown
							checked={[dnaData]}
							onCloseDownloadPopup={onClose}
							getMediaUrl={getMediaUrl}
							handleLoader={(loading) => handleLoader(loading)}
						/>
					) : (
						<MobileDownloadOption checked={[dnaData]} onClose={onClose} getMediaUrl={getMediaUrl} />
					)}
				</CommonModal>
			)}
		</>
	) : (
		<NoDataShow />
	);
};

export default DNA;
