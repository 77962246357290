import React from "react";

import Heading from "../common/Heading";
import { LAB_LINKS } from "constants/Common";

const DNADetail = (props) => {
	const { dnaData } = props;
	const details = [
		{ name: "Packet No", key: "vStnId" },
		{ name: "Report No", key: "WRAP_REPORT_NO" },
		{ name: "Lab", key: "lbNm" },
		{ name: "Shape", key: "shpNm" },
		{ name: "Carat", key: "crt" },
		{ name: "Color", key: "colNm" },
		{ name: "Clarity", key: "clrNm" },
		{ name: "Shade", key: "shdNm" },
		{ name: "Cut", key: "cutNm" },
		{ name: "Polish", key: "polNm" },
		{ name: "Symmetry", key: "symNm" },
		{ name: "Fluorescence", key: "fluNm" },
	];

	const measurements = [
		{ name: "Table %", key: "tblPer" },
		{ name: "Depth %", key: "depPer" },
		{ name: "Length", key: "length" },
		{ name: "Width", key: "width" },
		{ name: "Depth", key: "height" },
		{ name: "Ratio", key: "ratio" },
		{ name: "Crown Angle", key: "cAng" },
		{ name: " Crown Height", key: "cHgt" },
		{ name: "Pav Angle", key: "pAng" },
		{ name: "Pav Height", key: "pHgt" },
		{ name: "Girdle", key: "girdleStr" },
		{ name: "Culet", key: "cultNm" },
		{ name: "Laser Ins. ", key: "WRAP_LASER_INC" },
	];

	const inclusion = [
		{ name: "Center Natts", key: "blkTblNm" },
		{ name: "Side Natts", key: "blkSdNm" },
		{ name: "Center White", key: "wTblNm" },
		{ name: "Side White", key: "wSdNm" },
		{ name: "Table Open", key: "opTblNm" },
		{ name: "Crown Open", key: "opCrwnNm" },
		{ name: "Pavillion Open", key: "opPavNm" },
		{ name: "Eye Clean", key: "eClnNm" },
		{ name: "Heart & Arrow", key: "hANm" },
		{ name: "Brilliancy", key: "brlncyNm" },
		{ name: "Type2 Cert", key: "type2" },
		{ name: "Rough Source(COO)", key: "org" },
		{ name: "Program", key: "program" }
	];

	const LAB_FILTER = LAB_LINKS[dnaData?.lbNm]?.replace("***", dnaData?.rptNo);

	return (
		<>
			<div className="dnaDetailBlock ">
				<Heading title="Details" />
				<div className=" d-flex flex-wrap">
					{details?.map((dt, i) => (
						<div key={i} className="detail">
							<span>{dt?.name} : </span>
							{!dnaData?.lbNm === 'XALL' && dt?.key === "rptNo" ? (
								<span>
									<a
										href={LAB_FILTER}
										target="_blank"
										rel="noopener noreferrer"
										style={{ color: "rgb(0, 140, 186)" }}
									>
										{dnaData[dt.key] ? dnaData[dt.key] : "-"}
									</a>
								</span>
                            ) : (
                                <span>{dnaData[dt.key] ? dnaData[dt.key] : "-"}</span>
                            )}
						</div>
					))}
				</div>
			</div>
			<div className="dnaDetailBlock">
				<Heading title="Measurements" />
				<div className=" d-flex flex-wrap align-items-normal">
					{measurements.map((m, i) => (
						<div key={i} className="detail">
							<span>{m.name} : </span>
							<span>{dnaData[m.key] ? dnaData[m.key] : "-"}</span>
						</div>
					))}
				</div>
			</div>
			<div className="dnaDetailBlock">
				<Heading title="Inclusion Details" />
				<div className="d-flex flex-wrap">
					{inclusion.map((i, key) => (
						<div key={key} className="detail">
							<span>{i.name}: </span>
							<span>{dnaData[i.key] ? dnaData[i.key] : "-"}</span>
						</div>
					))}
				</div>
			</div>
			<div className="dnaDetailBlock width-100">
				{dnaData.kToSStr && (
					<div className="d-flex flex-wrap">
						<div className="detail">
							<span>Key To Symbol : </span>
							<span>{dnaData.kToSStr}</span>
						</div>
					</div>
				)}
				{dnaData.lbCmt && (
					<div className="d-flex flex-wrap">
						<div className="detail">
							<span>Report Comments :</span>
							<span>{dnaData.lbCmt}</span>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default DNADetail;
