import React, { useState, useEffect } from "react";

import split from "lodash/split";
import { getProjectSetting } from "components/DiamondList/DiamondListFunctions";

import { SessionStore } from "services/Storage";
import { isEmpty } from "util/utils";
import { PROJECT_SETTINGS_TYPE } from "constants/Common";

import { VIEW_DIAMOND } from "../../constants/apiConstant";
import UtilService from "../../services/util";
import DiamondFullVideoBlock from "./DiamondFullVideoBlock";

import "./diamondfullview.less";

const DiamondFullView = () => {
	const [lbNm, setLbNm] = useState(false);
	const toggle = () => setLbNm(!lbNm);
	const [mediaUrl, setMediaUrl] = useState(SessionStore.get("media-path-pattern") ?? {});
	const [data, setData] = useState({});
	const [dnaData, setDnaData] = useState({});
	const [currentIndex, setCurrentIndex] = useState(0);

	const getDiamondDetail = React.useCallback((id) => {
		const objData = {
			method: VIEW_DIAMOND.method,
			url: `${VIEW_DIAMOND.url}/${id}`,
			showLoader: true,
		};

		UtilService.callApi(objData, async (err, res) => {
			if (err) throw err;

			if (res && res.code === "OK") {
				setData(res?.data);
				setDnaData(res?.data);
			}
		});
	}, []);

	useEffect(() => {
		const id = split(window.location.pathname, "/")?.pop();
		id !== "diamondfullview" && getDiamondDetail(id);
	}, [getDiamondDetail]);

	useEffect(() => {
		if (!isEmpty(mediaUrl)) return;

		getProjectSetting(PROJECT_SETTINGS_TYPE.MEDIA_PATH, (data) => {
			setMediaUrl(data?.MEDIA_PATH_PATTERN ?? {});
			SessionStore.set("media-path-pattern", data?.MEDIA_PATH_PATTERN);
		});
	}, [mediaUrl]);

	const getMediaUrl = React.useCallback(
		(type) => {
			const url = mediaUrl?.[type]?.FILE_PATH;
			return url?.PATH?.replaceAll("***", data?.[url?.KEY]);
		},
		[data, mediaUrl],
	);

	const handleDNA = () => window.open(`/dna/${data.vStnId}`);
	return (
		<div className="viewdiamond viewdiamond-mobile">
			{
				<div className="mainContainer">
					<div className="dnaImageSwipe video">
						<DiamondFullVideoBlock
							className="video-m"
							dnaData={dnaData}
							currentIndex={currentIndex}
							getMediaUrl={getMediaUrl}
							setCurrentIndex={setCurrentIndex}
						/>
					</div>
				</div>
			}
			<div className="dnaDetailBlock">
				<table className="table">
					<thead>
						<tr>
							<th className="dnaImageTop" colSpan="14">
								<span>Report No : {data?.WRAP_REPORT_NO}</span>
							</th>
						</tr>
						<tr>
							<th>Lab</th>
							<th>Shape</th>
							<th>Carat</th>
							<th>Clarity</th>
							<th>Color</th>
							<th>Cut</th>
							<th>Pol</th>
							<th>Symm</th>
							<th>Fls</th>
							<th>Depth%</th>
							<th>Table%</th>
							<th>LWD</th>
							<th>Ratio</th>
							<th>DNA</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<span className="viewCerti">
									{data?.lbNm}
								</span>
							</td>
							<td>{data?.shpNm}</td>
							<td>{data?.crt}</td>
							<td>{data?.clrNm}</td>
							<td>{data?.colNm}</td>
							<td>{data?.cutNm}</td>
							<td>{data?.polNm}</td>
							<td>{data?.symNm}</td>
							<td>{data?.fluNm}</td>
							<td>{data?.depPer}</td>
							<td>{data?.tblPer}</td>
							<td>{data?.msrmnt}</td>
							<td>{(data?.ratio && data?.ratio !== 0) ? parseFloat(data?.ratio).toFixed(2) : '-'}</td>
							<td>
								<span className="viewCerti" onClick={handleDNA}>
									DNA
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			{data.certFile ? (
				<div className="certificate mt-30" style={{ display: "flex", justifyContent: "center" }}>
					<iframe title="cert File" src={getMediaUrl("certFile")} alt="" style={{ width: "80%" }} />
				</div>
			) : null}
		</div>
	);
};
export default DiamondFullView;
