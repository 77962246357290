import {
	SET_LOADER,
	FETCH_ERROR,
	FETCH_START,
	FETCH_SUCCESS,
	SWITCH_LANGUAGE,
	CURRENT_PAGE_DATA,
	HIDE_MESSAGE,
	SHOW_MESSAGE,
	CATCH_MASTER_DATA,
	PAGE_PERMISSION,
	RESET_SEARCH,
	MODIFY_SEARCH,
} from "../../constants/ActionTypes";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import UtilService from "../../services/util";

const INIT_STATE = {
	error: "",
	loading: false,
	message: "",
	pageData: null,
	locale: localStorage.getItem(`${LOCAL_STORAGE_VAR}-localisation`)
		? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-localisation`))
		: {},
	pagePermission: () =>
		UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`)
			? UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`)
			: {},
	strip: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case FETCH_START: {
			return { ...state, error: "", message: "", loading: true };
		}
		case FETCH_SUCCESS: {
			return { ...state, error: "", message: "", loading: false };
		}
		case SHOW_MESSAGE: {
			return { ...state, error: "", message: action.payload, loading: false };
		}
		case FETCH_ERROR: {
			return { ...state, loading: false, error: action.payload, message: "" };
		}
		case HIDE_MESSAGE: {
			return { ...state, loading: false, error: "", message: "" };
		}
		case CURRENT_PAGE_DATA: {
			return { ...state, pageData: action.payload };
		}
		case CATCH_MASTER_DATA: {
			return { ...state, masterData: action.payload };
		}
		case SET_LOADER: {
			return { ...state, loading: action.loading };
		}
		case SWITCH_LANGUAGE:
			return {
				...state,
				locale: action.payload,
			};
		case PAGE_PERMISSION: {
			return {
				...state,
				pagePermission: action.payload,
			};
		}
		case RESET_SEARCH: {
			return {
				...state,
				reset: action.payload,
			};
		}
		case MODIFY_SEARCH: {
			return {
				...state,
				modifySearch: action.payload.modifyData || {},
			};
		}
		case "STRIP_SHOW": {
			return {
				...state,
				strip: action.payload,
			};
		}
		default:
			return state;
	}
};
