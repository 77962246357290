import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import { Checkbox } from "antd";
import join from "lodash/join";
import map from "lodash/map";
import _isBoolean from "lodash/isBoolean";

import { shareExcel } from "components/DiamondList/DiamondListFunctions";

import SendEmailPopup from "./SendEmail";

import { DOWNLOAD_URL, VIEW_DNA_URL } from "../../../constants/Common";
import OpenNotification from "../CommonButton/OpenNotification";

const ShareDropdown = (props) => {
	const [sendEmail, setSendEmail] = useState(false);
	const [isExcel, setIsExcel] = useState(false);
	const [isCert, setIsCert] = useState(false);
	const [typeIIA, setTypeIIA] = useState(false);
	const [isImg, setIsImg] = useState(false);
	const [isImg2, setIsImg2] = useState(false);
	const [isFaceUp, setIsFaceUp] = useState(false);
	const [DarkField, setDarkField] = useState(false);
	const [Ideal, setIdeal] = useState(false);
	const [flourescence, setFlourescence] = useState(false);
	const [isVideo, setIsVideo] = useState(false);
	const [isPlt, setIsPlt] = useState(false);
	const [isHa, setIsHa] = useState(false);
	const [isAsset, setIsAsset] = useState(false);
	const [isArrow, setIsArrow] = useState(false);
	const flagRef = React.useRef(0);
	const [isVideo2, setIsVideo2] = useState(false);
	const [roughImage, setRoughimage] = useState(false);
	const [roughVideo, setRoughVideo] = useState(false);
	const [DImage, setDImage] = useState(false);
	const [images, setImages] = useState(false);
	const [videos, setVideos] = useState(false);
	const [certificates, setCertificates] = useState(false);
	const [roughs, setRoughs] = useState(false);
	const [excelPath, setExcelPath] = useState("");

	const ShareOption = (props) => {
		return (
			<li
				id={props.uiid}
				onClick={() => {
					props.onChange(!props.id);
				}}
				className={props.activeClass ? props.activeClass : ""}
			>
				<div className="dropdownBottomItem">
					<div className="dropdownIconCheckBox">
						<Checkbox onChange={props.onChange} checked={props.checked}></Checkbox>
					</div>
					<span className="shareOptionLabel">{props.title}</span>
				</div>
			</li>
		);
	};
	const SearchId = window?.location?.search?.slice(1);
	const handleExcel = React.useCallback(() => {
		shareExcel(
			map(props.checked, "id"),
			(path) => {
				setExcelPath(`${DOWNLOAD_URL}/data${path}`);
			},
			SearchId,
		);
	}, [props.checked, SearchId]);

	React.useEffect(() => {
		handleExcel();
	}, [handleExcel]);

	const WhatsAppShare = () => {
		const links = [];

		props.checked.forEach((i) => {
			if (isImg) links.push(`${VIEW_DNA_URL}/viewImage/RealImages/${i.vStnId}`);
			if (isPlt) links.push(`${VIEW_DNA_URL}/viewImage/PlottingImages/${i.vStnId}`);
			if (isHa || isArrow) links.push(`${VIEW_DNA_URL}/viewImage/HeartImages/${i.vStnId}`);
			if (isAsset) links.push(`${VIEW_DNA_URL}/viewImage/AssetScopeImages/${i.vStnId}`);
			if (DarkField) links.push(`${VIEW_DNA_URL}/viewImage/Dark_Field_White_BG/${i.vStnId}`);
			if (Ideal) links.push(`${VIEW_DNA_URL}/viewImage/IDEAL_White_BG/${i.vStnId}`);
			if (flourescence) links.push(`${VIEW_DNA_URL}/viewImage/FlsImages/${i.vStnId}`);
			if (isFaceUp) links.push(`${VIEW_DNA_URL}/viewImage/Office_Light_Black_BG/${i.vStnId}`);
			if (isImg2) links.push(`${VIEW_DNA_URL}/viewImage/viewer3Image/${i.vStnId}`);
			if (isVideo) links.push(`${VIEW_DNA_URL}/viewImage/viewer4Video/${i.vStnId}`);
			if (isVideo2) links.push(`${VIEW_DNA_URL}/viewImage/viewer3Video/${i.vStnId}`);
			if (isCert) links.push(`${VIEW_DNA_URL}/viewImage/CertiImages/${i.vStnId}`);
			if (typeIIA) links.push(`${VIEW_DNA_URL}/viewImage/TYPE_IIA/${i.vStnId}`);
			if (roughImage) links.push(`${VIEW_DNA_URL}/viewImage/RoughImages/${i.vStnId}`);
			if (roughVideo) links.push(`${VIEW_DNA_URL}/viewImage/RoughVideo/${i.vStnId}`);
			if (DImage) links.push(`${VIEW_DNA_URL}/viewImage/PlanImages/${i.vStnId}`);
		});
		if (isExcel) links.push(excelPath);
		window.open(`https://web.whatsapp.com/send?text=${join(links, "%0A%0A")}`);
	};

	const SkypeShare = () => {
		const links = [];

		props.checked.forEach((i) => {
			if (isImg) links.push(`${VIEW_DNA_URL}/viewImage/RealImages/${i.vStnId}`);
			if (isPlt) links.push(`${VIEW_DNA_URL}/viewImage/PlottingImages/${i.vStnId}`);
			if (isHa || isArrow) links.push(`${VIEW_DNA_URL}/viewImage/HeartImages/${i.vStnId}`);
			if (isAsset) links.push(`${VIEW_DNA_URL}/viewImage/AssetScopeImages/${i.vStnId}`);
			if (DarkField) links.push(`${VIEW_DNA_URL}/viewImage/Dark_Field_White_BG/${i.vStnId}`);
			if (Ideal) links.push(`${VIEW_DNA_URL}/viewImage/IDEAL_White_BG/${i.vStnId}`);
			if (flourescence) links.push(`${VIEW_DNA_URL}/viewImage/FlsImages/${i.vStnId}`);
			if (isFaceUp) links.push(`${VIEW_DNA_URL}/viewImage/Office_Light_Black_BG/${i.vStnId}`);
			if (isImg2) links.push(`${VIEW_DNA_URL}/viewImage/viewer3Image/${i.vStnId}`);
			if (isVideo) links.push(`${VIEW_DNA_URL}/viewImage/viewer4Video/${i.vStnId}`);
			if (isVideo2) links.push(`${VIEW_DNA_URL}/viewImage/viewer3Video/${i.vStnId}`);
			if (isCert) links.push(`${VIEW_DNA_URL}/viewImage/CertiImages/${i.vStnId}`);
			if (typeIIA) links.push(`${VIEW_DNA_URL}/viewImage/TYPE_IIA/${i.vStnId}`);
			if (roughImage) links.push(`${VIEW_DNA_URL}/viewImage/RoughImages/${i.vStnId}`);
			if (roughVideo) links.push(`${VIEW_DNA_URL}/viewImage/RoughVideo/${i.vStnId}`);
			if (DImage) links.push(`${VIEW_DNA_URL}/viewImage/PlanImages/${i.vStnId}`);
		});

		if (isExcel) {
			links.push(excelPath);
		}
		window.open(`https://web.skype.com/share?url=${join(links, "%0A%0A")}`);
	};

	const updateAllImage = React.useCallback((check) => {
		setIsImg(check);
		setIsImg2(check);
		setIsPlt(check);
		setIsHa(check);
		setIsAsset(check);
		setIsFaceUp(check);
		setDarkField(check);
		setIdeal(check);
		setFlourescence(check);
		setIsArrow(check);
	}, []);

	const checkAllImages = React.useCallback(
		(allImages = null) => {
			if (_isBoolean(allImages)) {
				flagRef.current = flagRef.current + (allImages ? +2 : -2);
				return updateAllImage(allImages);
			}
			if (isImg && isImg2 && isPlt && isHa && isAsset && isArrow && isFaceUp && DarkField && Ideal && flourescence)
				setImages(true);
			else setImages(false);
		},
		[DarkField, Ideal, flourescence, isArrow, isAsset, isFaceUp, isHa, isImg, isImg2, isPlt, updateAllImage],
	);

	React.useEffect(() => {
		checkAllImages();
	}, [checkAllImages]);

	const updateAllVideo = React.useCallback((allVideo) => {
		setIsVideo(allVideo);
		setIsVideo2(allVideo);
	}, []);

	const checkAllVideo = React.useCallback(
		(allVideo = null) => {
			if (_isBoolean(allVideo)) {
				flagRef.current = flagRef.current + (allVideo ? +2 : -2);
				return updateAllVideo(allVideo);
			}
			if (isVideo && isVideo2) setVideos(true);
			else setVideos(false);
		},
		[isVideo, isVideo2, updateAllVideo],
	);

	React.useEffect(() => {
		checkAllVideo();
	}, [checkAllVideo]);

	const updateAllCertificate = React.useCallback((allCertificate) => {
		setIsCert(allCertificate);
		setTypeIIA(allCertificate);
	}, []);

	const checkAllCertificate = React.useCallback(
		(allCertificate = null) => {
			if (_isBoolean(allCertificate)) {
				flagRef.current = flagRef.current + (allCertificate ? +2 : -2);
				return updateAllCertificate(allCertificate);
			}
			if (isCert && typeIIA) setCertificates(true);
			else setCertificates(false);
		},
		[isCert, typeIIA, updateAllCertificate],
	);

	React.useEffect(() => {
		checkAllCertificate();
	}, [checkAllCertificate]);

	const updateAllRough = React.useCallback((allRough) => {
		setRoughVideo(allRough);
		setRoughimage(allRough);
		setDImage(allRough);
	}, []);

	const checkAllRoughs = React.useCallback(
		(allRough = null) => {
			if (_isBoolean(allRough)) {
				flagRef.current = flagRef.current + (allRough ? +2 : -2);
				return updateAllRough(allRough);
			}
			if (roughImage && roughVideo && DImage) setRoughs(true);
			else setRoughs(false);
		},
		[DImage, roughImage, roughVideo, updateAllRough],
	);

	React.useEffect(() => {
		checkAllRoughs();
	}, [checkAllRoughs]);

	return (
		<>
			<div>
				<div className="searchPopupCommon">
					<div className="shareOptionSet">
						<div className="shareOptionSetItem shareOptionImage">
							<ul>
								<div className="dropdownMainTitle">
									<div className="dropdownIconCheckBox">
										<Checkbox checked={images} onChange={() => checkAllImages(!images)}></Checkbox>
									</div>
									<span>Images : </span>
								</div>
								<div className="dropdownRightCheck">
									<ShareOption
										title="B2B Image"
										checked={isImg}
										//activeClass={isImg === true ? "active" : ""}
										onChange={() => {
											setIsImg(!isImg);
										}}
									/>
									<ShareOption
										title="B2C Image"
										checked={isImg2}
										onChange={() => {
											setIsImg2(!isImg2);
										}}
									/>
									<ShareOption title="Plotting" checked={isPlt} onChange={() => setIsPlt(!isPlt)} />
									<ShareOption title="Heart & Arrow" checked={isHa} onChange={() => setIsHa(!isHa)} />
									<ShareOption title="Asset Scope" checked={isAsset} onChange={() => setIsAsset(!isAsset)} />
									<ShareOption title="Arrow Image" checked={isArrow} onChange={() => setIsArrow(!isArrow)} />
									<ShareOption title="Face Up Image" checked={isFaceUp} onChange={() => setIsFaceUp(!isFaceUp)} />
									<ShareOption
										title="Dark - Field Image"
										checked={DarkField}
										onChange={() => setDarkField(!DarkField)}
									/>
									<ShareOption title="Ideal Scope Image" checked={Ideal} onChange={() => setIdeal(!Ideal)} />
									<ShareOption
										title="Flouresence Image"
										checked={flourescence}
										onChange={() => setFlourescence(!flourescence)}
									/>
								</div>
							</ul>
							<ul>
								<div className="dropdownMainTitle">
									<div className="dropdownIconCheckBox">
										<Checkbox checked={videos} onChange={() => checkAllVideo(!videos)}></Checkbox>
									</div>
									<span>Video : </span>
								</div>
								<div className="dropdownRightCheck">
									<ShareOption title="B2B Video" checked={isVideo} onChange={() => setIsVideo(!isVideo)} />
									<ShareOption title="B2C Video" checked={isVideo2} onChange={() => setIsVideo2(!isVideo2)} />
								</div>
							</ul>
							<ul className="d-flex">
								<div className="width-50 d-flex align-items-center">
									<div className="dropdownMainTitle">
										<div className="dropdownIconCheckBox">
											<Checkbox checked={certificates} onChange={() => checkAllCertificate(!certificates)}></Checkbox>
										</div>
										<span>Certificate : </span>
									</div>
									<div className="dropdownRightCheck">
										<ShareOption title="Certificate" checked={isCert} onChange={() => setIsCert(!isCert)} />
										<ShareOption title="Type IIA" checked={typeIIA} onChange={() => setTypeIIA(!typeIIA)} />
									</div>
								</div>
								<div className="width-50 d-flex align-items-center excelShare">
									<div className="dropdownMainTitle">
										<span>Excel : </span>
									</div>
									<div className="dropdownRightCheck">
										<div>
											<ShareOption
												checked={isExcel}
												onChange={() => setIsExcel(!isExcel)}
												//onChange={() => setIsExcel(!isExcel)}
												title="Excel"
											/>
										</div>
									</div>
								</div>
							</ul>
							<ul>
								<div className="dropdownMainTitle">
									<div className="dropdownIconCheckBox">
										<Checkbox checked={roughs} onChange={() => checkAllRoughs(!roughs)}></Checkbox>
									</div>
									<span>Rough : </span>
								</div>
								<div className="dropdownRightCheck">
									<ShareOption title="Rough Scope" checked={roughImage} onChange={() => setRoughimage(!roughImage)} />
									<ShareOption title="3D Image" checked={DImage} onChange={() => setDImage(!DImage)} />
									<ShareOption title="Rough Video" checked={roughVideo} onChange={() => setRoughVideo(!roughVideo)} />
								</div>
							</ul>
						</div>
					</div>
					<div className="commonModalButton mt-50">
						{/* {USER_PERMIT && USER_PERMIT.SHARE_VIA_MAIL.view && ( */}
						<button
							className="fillButton"
							onClick={() => {
								if (
									isAsset ||
									isHa ||
									isCert ||
									isExcel ||
									isImg ||
									isPlt ||
									isVideo ||
									isArrow ||
									isImg2 ||
									typeIIA ||
									isFaceUp ||
									DarkField ||
									Ideal ||
									flourescence ||
									isVideo2 ||
									roughImage ||
									roughVideo ||
									DImage
								) {
									setSendEmail(true);
								} else {
									OpenNotification({
										type: "error",
										title: "Please Select atleast 1 Option to Share.",
									});
								}
								//props.onCloseSharePopup();
							}}
						>
							Email
						</button>
						{/* )} */}
						{/* {USER_PERMIT && USER_PERMIT.SHARE_VIA_WHATSAPP.view && ( */}
						<button onClick={WhatsAppShare} className="fillButton ml-5">
							WhatsApp
						</button>
						{/* )} */}
						{/* {USER_PERMIT && USER_PERMIT.SHARE_VIA_SKYPE.view && ( */}
						<button onClick={SkypeShare} className="fillButton ml-5">
							Skype
						</button>
						{/* )} */}
					</div>
				</div>
			</div>
			<SendEmailPopup
				sendEmail={sendEmail}
				onCancel={() => setSendEmail(!sendEmail)}
				ids={props.orderDiamond ? map(props.checked, "diamondId") : map(props.checked, "id")}
				certFile={isCert}
				excelPath={excelPath}
				hAFile={isHa}
				img={isImg}
				isPlt={isPlt}
				isReal={isImg}
				assetFile={isAsset}
				videoFile={isVideo}
				isExcel={isExcel}
				arrowFile={isArrow}
				mp4Video={isVideo2}
				type2={typeIIA}
				roughVideo={roughVideo}
				darkFieldImage={DarkField}
				flsImage={flourescence}
				idealWhiteImage={Ideal}
				roughImage={roughImage}
				planImg={DImage}
				faceUp={isFaceUp}
				b2cRealImage={isImg2}
				checked={props.checked}
				orderDiamond={window.location.pathname === "/account/confirm-list" ? true : false}
			/>
		</>
	);
};
export default withRouter(ShareDropdown);
