/**
 * Handles exceptions in a function
 * @param   {Function} func
 * @param   {Object}   options
 * @returns {Promise}
 */
export const asyncHandler = async (func, options = {}) => {
	const defaultOptions = { returnStatus: true, returnError: true };
	options = { ...defaultOptions, ...options };

	try {
		const response = await func();
		return options.returnStatus ? [false, response] : response;
	} catch (_error) {
		// logWarn({ error: _error });
		const error = options.returnError ? _error : undefined;
		return options.returnStatus ? [true, error] : error;
	}
};
