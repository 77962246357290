import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";

const INIT_STATE = {
	selectedRows: [],
	selectedRowIds: [],
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case "SELECT_ROW": {
			const currentRows = state.selectedRows[action.currentType] || [];
			const currentRowIds = state.selectedRowIds[action.currentType] || [];

			return {
				...state,
				selectedRows: {
					...state.selectedRows,
					[action.currentType]: uniqBy([action.payload, ...currentRows], "id"),
				},
				selectedRowIds: {
					...state.selectedRowIds,
					[action.currentType]: uniq([...currentRowIds, action.payload.id]),
				},
			};
		}
		case "UNSELECT_ROW": {
			const currentRows = state.selectedRows[action.currentType] || [];
			const newSelectedRows = [...currentRows];
			const selectedRowsIndex = newSelectedRows.findIndex((s) => s.id === action.payload.id);
			newSelectedRows.splice(selectedRowsIndex, 1);
			return {
				...state,
				selectedRows: {
					...state.selectedRows,
					[action.currentType]: uniqBy([...newSelectedRows], "id"),
				},
				selectedRowIds: {
					...state.selectedRowIds,
					[action.currentType]: uniq([...newSelectedRows.map((r) => r.id)]),
				},
			};
		}
		case "BULK_SELECT_ROW": {
			const { payload: bulkRows, currentType } = action;
			const stateRows = state.selectedRows[currentType] || [];
			const stateRowIds = state.selectedRowIds[currentType] || [];
			const currentRows = [...stateRows];

			bulkRows.map((current) => {
				const { status, row } = current;

				if (!!status && !stateRowIds.includes(row.id)) {
					currentRows.push(row);
				} else if (!status) {
					const currentIndex = currentRows.findIndex((r) => r.id === row.id);
					currentRows.splice(currentIndex, 1);
				}
				const tableRows = document.querySelectorAll(`[table-row="${currentType}${row.id}"]`);

				for (const row of tableRows) {
					row.classList[status ? "add" : "remove"]("selectTr");
				}

				// Explicitly return a value from the map function
				return null;
			});

			return {
				...state,
				selectedRows: {
					...state.selectedRows,
					[currentType]: [...currentRows],
				},
				selectedRowIds: {
					...state.selectedRowIds,
					[currentType]: [...currentRows].map((r) => r.id),
				},
			};
		}
		case "@RESET":
		case "RESET_SELECTED_ROWS": {
			return {
				...state,
				selectedRows: {},
				selectedRowIds: {},
			};
		}
		default:
			return state;
	}
};
