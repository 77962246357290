import { memoize } from "lodash";
import { forOwn, cloneDeep } from "lodash";

import { axios } from "util/Api";
import { asyncHandler } from "util/asyncHandler";
export const typeOf = (input, type) => input?.constructor?.name === type ?? null;

export const isArray = (input) => typeOf(input, "Array");

export const isObject = (input) => typeOf(input, "Object");

export const isString = (input) => typeOf(input, "String");

export const isFunction = (input) => typeOf(input, "Function");

export const isHTMLElement = (input) => input instanceof HTMLElement;

export const formatCarat = (carat) => parseFloat(parseInt(carat * 100) / 100).toFixed(2);

export const catchError = (func, onError) => {
	const handleError = (error) => {
		return onError?.(error);
	};

	try {
		const output = func?.();
		return output?.constructor?.name === "Promise" ? output?.catch?.(handleError) : output;
	} catch (error) {
		return handleError(error);
	}
};

export const isEmpty = (input, options) => {
	options = { isEmpty: [], isNotEmpty: [], ...options };

	if (options.isEmpty?.includes?.(input)) return true;
	if (options.isNotEmpty?.includes?.(input)) return false;
	if ([undefined, null].includes(input)) return true;

	if (input?.constructor?.name === "Array") return !input.length;
	if (input?.constructor?.name === "Number") return Number.isNaN(input);
	if (input?.constructor?.name === "Object") return !Object.keys(input).length;
	if (input?.constructor?.name === "String") return !input.trim().length;

	return false;
};

export const isNotEmpty = (...args) => !isEmpty(...args);

export const pruneEmpty = (obj) => {
	const prune = (current) => {
		forOwn(current, (value, key) => {
			if (isEmpty(value) || ((isObject(value) || isArray(value)) && isEmpty(prune(value)))) delete current[key];
		});
		if (isArray(current)) current = current.filter(isNotEmpty);
		return current;
	};
	return prune(cloneDeep(obj));
};

export const returnIfNotEmpty = (value, replaceWith) => (isEmpty(value) ? replaceWith : value);

export const hasKey = (object, key) => isObject(object) && !isEmpty(object) && Object.keys(object).includes(key);

export const runInDevelopment = (callback) =>
	(isEmpty(process.env.REACT_APP_ENV) || process.env.REACT_APP_ENV === "development") && callback();

export const logInfo = (...args) => runInDevelopment(() => console.info(...args)); // eslint-disable-line no-console
export const logWarn = (...args) => runInDevelopment(() => console.warn(...args)); // eslint-disable-line no-console
export const logTable = (...args) => runInDevelopment(() => console.table(...args)); // eslint-disable-line no-console

export const sortArrayByKey = (key = "id", desc = false) => {
	if (!isString(key)) return undefined;
	const n = { less: desc ? 1 : -1, more: desc ? -1 : 1 };
	return (curr, next) => (curr?.[key] < next?.[key] ? n.less : curr?.[key] > next?.[key] ? n.more : 0);
};

export const padArray = function (list, length, fillWith) {
	return list.concat(Array(length).fill(fillWith)).slice(0, length);
};

export const convertArrayToObject = (array, key) => {
	const initialValue = {};
	if (isEmpty(array)) return initialValue;
	return array.reduce((obj, item) => {
		return {
			...obj,
			[item[key]]: item,
		};
	}, initialValue);
};

export const objectToQueryString = (object) =>
	`?${Object.keys(object)
		.map((key) => `${key}=${object?.[key]?.toString ? object[key].toString() : ""}`)
		.join("&")}`;

export const queryStringToObject = (location = window.location) =>
	!isEmpty(location.search)
		? JSON.parse(
				`{"${decodeURI(location.search.substr(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`,
		  )
		: {};

export const viewFile = (path) =>
	asyncHandler(async () => {
		const response = await axios.get(path, { responseType: "blob" });
		const blobUrl = window.URL.createObjectURL(response.data);
		const anchor = document.createElement("a");
		void ((anchor.style.display = "none"), (anchor.href = blobUrl), (anchor.target = "_blank"));
		void (document.body.appendChild(anchor), anchor.click(), anchor.remove(), window.URL.revokeObjectURL(blobUrl));
	});

export const getBlobUrl = async (path) => {
	const response = await axios.get(path, { responseType: "blob" });
	return window.URL.createObjectURL(response?.data);
};

export const printFile = (path) =>
	asyncHandler(async () => {
		const blobUrl = await getBlobUrl(path);
		const iframe = document.querySelector("#PrintFrame") ?? document.createElement("iframe");
		if (iframe?.src) window.URL.revokeObjectURL(iframe.src);
		void ((iframe.style.display = "none"), (iframe.src = blobUrl), (iframe.height = "100%"), (iframe.width = "100%"));
		void ((iframe.id = "PrintFrame"), document.body.appendChild(iframe), iframe.contentWindow.print());
	});

export const downloadFile = (path, name) =>
	asyncHandler(async () => {
		const blobUrl = await getBlobUrl(path);
		const anchor = document.createElement("a");
		void ((anchor.style.display = "none"), (anchor.href = blobUrl), (anchor.download = name ?? path.split("/").pop()));
		void (document.body.appendChild(anchor), anchor.click(), anchor.remove(), window.URL.revokeObjectURL(blobUrl));
	});

export const getImagePath = (fileName) => `https://s3.ap-south-1.amazonaws.com/finestargroup/RealImages/${fileName}`;

export const getUserName = memoize((user, replace = "-") => {
	const name = [user?.firstName, user?.lastName].filter(isNotEmpty);
	return !isEmpty(name) ? name.join(" ") : user?.name ?? user?.username ?? replace;
});

export const randomString = (
	length = 50,
	characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
) => {
	let result = "";
	for (let i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * characters.length));
	return result;
};

export const capitalize = (input) =>
	input?.replace(/_/g, " ")?.replace(/(\w+)/g, (x) => x[0].toUpperCase() + x.substring(1));

export function compose(...funcs) {
	if (funcs.length === 0) return (arg) => arg;
	if (funcs.length === 1) return funcs[0];

	return funcs.reduce((a, b) => (...args) => a(b(...args))); // prettier-ignore
}

export const checkSubset = (parentArray, subsetArray) => {
	return subsetArray.every((el) => {
		return parentArray.includes(el);
	});
};
