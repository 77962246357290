import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, TreeSelect } from "antd";
import _ from "lodash";

import "./style.less";

import { LOCAL_STORAGE_VAR } from "constants/Common";
import { DIAMOND_DATA_BY_ID } from "constants/apiConstant";
import UtilService from "services/util";
import useUrlSearchQuery from "hooks/useUrlSearchQuery";

import DISP_FILTER from "./filter.json";

import OpenNotification from "components/common/CommonButton/OpenNotification";
import CommonModal from "components/common/CommonModal";
import DownloadDropdown from "components/common/DiamondListing/DownloadDropdown";
import ShareDropdown from "components/common/DiamondListing/ShareDropdown";
import Image from "components/common/Image";
import Loader from "components/common/Loader";
import NoDataShow from "components/common/NoDataShow";
import DiamondImg from "./DiamondImg";
import NakedEye from "./NakedEye";
import StoneDetails from "./StoneDetails";
import ViewCert from "./ViewCert";
import VisualDisplay from "./VisualDisplay";

export default function CompareDna() {
	const searchParams = useUrlSearchQuery();

	const [isShare, setIsShare] = useState(false);
	const [isDownload, setIsDownload] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [dnaData, setDnaData] = useState([]);

	const [dispFilter, setDispFilter] = useState({});
	const FILTER = {
		IMAGES: "images",
		VIDEO: "video",
		NAKED_EYE: "nakedEye",
		CERTIFICATES: "certificates",
		DIAM_DETAILS: "diamDetails",
	};

	const [visualDisplayTab, setVisualDisplayTab] = useState("1");

	const [viewCertTab, setViewCertTab] = useState("");

	const [neNav1, setNeNav1] = useState(null);
	const [neNav2, setNeNav2] = useState(null);

	const [diNav1, setDiNav1] = useState(null);
	const [diNav2, setDiNav2] = useState(null);

	const isGuest = localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);

	const getDiamondDetail = React.useCallback(async () => {
		const vStnId = searchParams.get("id")?.split(",");

		const objData = {
			method: DIAMOND_DATA_BY_ID.method,
			url: DIAMOND_DATA_BY_ID.url,
			showLoader: true,
			request: {
				isHitCounter: true,
				vStnId,
			},
		};

		UtilService.callApi(objData, async (err, data) => {
			if (data.code === "E_NOT_FOUND") {
				console.error("data.code", data.code);
			} else if (err) {
				setIsLoading(false);

				if (data.status === 502) {
					return OpenNotification({
						type: "error",
						title: err?.message,
					});
				} else {
					return OpenNotification({
						type: "error",
						title: err?.message,
					});
				}
			}

			if (data?.code === "OK") {
				console.log("data", data);
				const dataList = data?.data?.slice();
				if (!_.isEmpty(dataList)) {
					setViewCertTab(dataList[0].lbNm);
					setDnaData(dataList);
				}
			}
			setIsLoading(false);
		});
	}, [searchParams, setIsLoading, setViewCertTab, setDnaData]);

	useEffect(() => {
		getDiamondDetail();
	}, [getDiamondDetail]);

	const onClose = () => {
		setIsShare(false);
		setIsDownload(false);
	};

	const handleLoader = (loading) => {
		loading
			? document.body.classList.add("loading-indicator")
			: document.body.classList.remove("loading-indicator");
	};

	const filterTreeNode = (search, item) => {
		return item.props?.title?.toLowerCase()?.indexOf(search.toLowerCase()) >= 0;
	};

	const getParentKey = (key, tree = DISP_FILTER) => {
		let parentKey;
		for (let i = 0; i < tree.length; i++) {
			const node = tree[i];
			if (node.children) {
				if (node.children.some((item) => item.key === key)) {
					parentKey = node.key;
				} else if (getParentKey(key, node.children)) {
					parentKey = getParentKey(key, node.children);
				}
			}
		}
		return parentKey;
	};

	function onFilterChange(_val, _lbl, node) {
		let tmp = { ...dispFilter };

		const { checked, triggerValue: tv } = node;
		const parentKey = getParentKey(tv) || tv;

		if (tv === parentKey) {
			if (checked) {
				tmp[parentKey] = DISP_FILTER.find((v) => v.key === parentKey).children;
			} else {
				delete tmp[parentKey];
			}
		} else {
			if (checked) {
				const parObj = DISP_FILTER.find((v) => v.key === parentKey);
				const childObj = parObj.children.find((v) => v.key === tv);
				if (!tmp[parentKey]) tmp[parentKey] = [];
				tmp[parentKey].push(childObj);
			} else {
				tmp[parentKey] = tmp[parentKey].filter((v) => v.key !== tv);
				if (tmp[parentKey].length === 0) delete tmp[parentKey];
			}
		}

		setDispFilter(tmp);
	}

	const fitlerProps = {
		treeData: DISP_FILTER,
		onChange: onFilterChange,
		treeCheckable: true,
		showCheckedStrategy: TreeSelect.SHOW_PARENT,
		treeDefaultExpandAll: true,
		searchPlaceholder: "Please select",
		filterTreeNode: filterTreeNode,
		dropdownClassName: "dna-filter",
		style: {
			minWidth: "200px",
		},
	};

	return isLoading ? (
		<Loader loading={isLoading} fixed />
	) : (
		<>
			<Card
				size="small"
				title="Compare Stone"
				className="card-header"
				bordered={false}
				extra={
					<>
						{!isGuest && (dnaData[0]?.hideDna !== "DNA" || dnaData[1]?.hideDna !== "DNA") && (
							<>
								<Button onClick={() => setIsShare(true)}>
									<Image
										src={require("assets/svg/DNA/share-theme.svg")}
										alt=""
										className="mr-10 icon"
									/>
									Share
								</Button>

								<Button className="ml-5" onClick={() => setIsDownload(true)}>
									<Image
										src={require("assets/svg/DNA/download.svg")}
										alt=""
										className="mr-10 icon"
									/>
									Download
								</Button>
							</>
						)}

						{isShare && (
							<CommonModal
								modalSize="ListingModal"
								title="Share Stone"
								handleCancel={() => setIsShare(false)}
								visible={isShare}
								wrapClassName=""
							>
								<ShareDropdown checked={dnaData} onCloseSharePopup={onClose} />
							</CommonModal>
						)}

						{isDownload && (
							<CommonModal
								modalSize="ListingModal"
								title="Download Stone"
								handleCancel={() => setIsDownload(false)}
								visible={isDownload}
								wrapClassName=""
							>
								<DownloadDropdown
									checked={dnaData}
									onCloseDownloadPopup={onClose}
									handleLoader={(loading) => handleLoader(loading)}
								/>
							</CommonModal>
						)}
					</>
				}
			>
				<Row gutter={[8, 8]}>
					{/* Filter */}
					<Col sm={24} md={24} lg={{ span: 16, offset: 4 }}>
						<Card size="small">
							<div className="d-flex justify-content-between align-items-center">
								<span style={{ fontWeight: 600, width: "100px" }}>
									<img src={require("../../../assets/svg/CompareDna/filter.svg")} alt="" />
									&nbsp;Filter
								</span>

								<div style={{ maxWidth: "80%" }}>
									<TreeSelect
										disabled={dnaData[0]?.hideDna === "DNA" && dnaData[1]?.hideDna === "DNA"}
										{...fitlerProps}
									/>
								</div>
							</div>
						</Card>
					</Col>

					{/* 1 - Packet Details */}
					<Col sm={24} md={12} lg={{ span: 8, offset: 4 }}>
						<Card
							size="small"
							title={`Packet No. : ${dnaData[0]?.vStnId || "-"}`}
							className="card-header title-center"
						>
							{dnaData[0]?.hideDna !== "DNA" ? (
								<>
									{/* Stone Details */}
									{(_.isEmpty(dispFilter) || dispFilter[FILTER.DIAM_DETAILS]) && (
										<div className="section stone-detail">
											<StoneDetails dnaData={dnaData[0]} />
										</div>
									)}

									{/* Video/Image Tab */}
									{(_.isEmpty(dispFilter) || dispFilter[FILTER.VIDEO]) && (
										<div className="section">
											<VisualDisplay
												dnaData={dnaData[0]}
												tabIndex={visualDisplayTab}
												changeTab={setVisualDisplayTab}
											/>
										</div>
									)}

									{/* GIA/IGI/HRD Certificates */}
									{(_.isEmpty(dispFilter) || dispFilter[FILTER.CERTIFICATES]) && (
										<div className="section">
											<ViewCert
												dnaData={dnaData[0]}
												tabIndex={viewCertTab}
												changeTab={setViewCertTab}
											/>
										</div>
									)}

									{/* Naked Eye */}
									{(_.isEmpty(dispFilter) || dispFilter[FILTER.NAKED_EYE]) && (
										<div className="section">
											<div className="title">Naked Eye</div>

											<div className="box-container mt-20">
												<NakedEye
													dnaData={dnaData[0]}
													navFor={neNav2}
													setNav={setNeNav1}
													filter={
														dispFilter[FILTER.NAKED_EYE] ||
														DISP_FILTER.find((v) => v.key === FILTER.NAKED_EYE).children
													}
												/>
											</div>
										</div>
									)}

									{/* Diamond Images */}
									{(_.isEmpty(dispFilter) || dispFilter[FILTER.IMAGES]) && (
										<div className="section">
											<div className="title">Diamond Image</div>

											<div className="box-container mt-20">
												<DiamondImg
													dnaData={dnaData[0]}
													navFor={diNav2}
													setNav={setDiNav1}
													filter={
														dispFilter[FILTER.IMAGES] ||
														DISP_FILTER.find((v) => v.key === FILTER.IMAGES).children
													}
												/>
											</div>
										</div>
									)}
								</>
							) : (
								<NoDataShow style={{ height: "300px" }} />
							)}
						</Card>
					</Col>

					{/* 2 - Packet Details */}
					<Col sm={24} md={12} lg={8}>
						<Card
							size="small"
							title={`Packet No. : ${dnaData[1]?.vStnId || "-"}`}
							className="card-header title-center"
						>
							{dnaData[1]?.hideDna !== "DNA" ? (
								<>
									{/* Stone Details */}
									{(_.isEmpty(dispFilter) || dispFilter[FILTER.DIAM_DETAILS]) && (
										<div className="section stone-detail">
											<StoneDetails dnaData={dnaData[1]} />
										</div>
									)}

									{/* Video/Image Tab */}
									{(_.isEmpty(dispFilter) || dispFilter[FILTER.VIDEO]) && (
										<div className="section">
											<VisualDisplay
												dnaData={dnaData[1]}
												tabIndex={visualDisplayTab}
												changeTab={setVisualDisplayTab}
											/>
										</div>
									)}

									{/* GIA/IGI Certificates */}
									{(_.isEmpty(dispFilter) || dispFilter[FILTER.CERTIFICATES]) && (
										<div className="section">
											<ViewCert
												dnaData={dnaData[1]}
												tabIndex={viewCertTab}
												changeTab={setViewCertTab}
											/>
										</div>
									)}

									{/* Naked Eye */}
									{(_.isEmpty(dispFilter) || dispFilter[FILTER.NAKED_EYE]) && (
										<div className="section">
											<div className="title">Naked Eye</div>

											<div className="box-container mt-20">
												<NakedEye
													dnaData={dnaData[1]}
													navFor={neNav1}
													setNav={setNeNav2}
													filter={
														dispFilter[FILTER.NAKED_EYE] ||
														DISP_FILTER.find((v) => v.key === FILTER.NAKED_EYE).children
													}
												/>
											</div>
										</div>
									)}

									{/* Diamond Images */}
									{(_.isEmpty(dispFilter) || dispFilter[FILTER.IMAGES]) && (
										<div className="section">
											<div className="title">Diamond Image</div>

											<div className="box-container mt-20">
												<DiamondImg
													dnaData={dnaData[1]}
													navFor={diNav1}
													setNav={setDiNav2}
													filter={
														dispFilter[FILTER.IMAGES] ||
														DISP_FILTER.find((v) => v.key === FILTER.IMAGES).children
													}
												/>
											</div>
										</div>
									)}
								</>
							) : (
								<NoDataShow style={{ height: "300px" }} />
							)}
						</Card>
					</Col>
				</Row>
			</Card>
		</>
	);
}
