import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { store } from "./appRedux/store";
import Root from "./config/Root";

import "styles/global.less";

import "styles/App.less";
import "styles/auth.less";

import "styles/DiamondList.less";
import "styles/Compare.less";

ReactDOM.render(
	<Provider store={store}>
		<Root />
	</Provider>,
	document.getElementById("root"),
);
