import React from "react";
import { Card } from "antd";

function Unavailable({ msg = "Data Not Available!", styles }) {
	return (
		<Card size="small" className="unavailable" style={{ ...styles }}>
			<h4>{msg}</h4>
		</Card>
	);
}

function PrevArrow(props) {
	const { className, onClick } = props;

	return (
		<img
			src={require("../../../assets/svg/CompareDna/prev-arrow.svg")}
			alt="Scroll To Prev"
			className={className}
			onClick={onClick}
		/>
	);
}

function NextArrow(props) {
	const { className, onClick } = props;
	return (
		<img
			src={require("../../../assets/svg/CompareDna/next-arrow.svg")}
			alt="Scroll To Next"
			className={className}
			onClick={onClick}
		/>
	);
}

export { Unavailable, PrevArrow, NextArrow };
