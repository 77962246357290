import { LocalStore } from "services/Storage";

import { axios } from "util/Api";
import { history } from "util/history";

import { USER_LOGOUT } from "constants/apiConstant";

import { store } from "appRedux/store";

export const logout = async () => {
	axios.request({ ...USER_LOGOUT, headers: { Authorization: `JWT ${LocalStore.get("token")}` } }).catch(console.error);
	LocalStore.deleteAll();
	store.dispatch({ type: "@@RESET" });
	history.push("/signin");
};
