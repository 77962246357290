import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { history } from "util/history";

import reducers from "../reducers";

const routeMiddleware = routerMiddleware(history);

const composeEnhancers =
	process.env.REACT_APP_ENV === "development" && window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		: compose;

export const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk, routeMiddleware)));
