import React, { Component } from "react";

import LoginForm from "./Auth/LoginForm";

import { LOCAL_STORAGE_VAR } from "../constants/Common";

class SignIn extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showInfo: true,
		};
	}

	componentDidMount() {
		const showInfo = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-cookie-policy`));

		if (showInfo) {
			this.setState({ showInfo: false });
		}
	}

	setCookie = () => {
		localStorage.setItem(`${LOCAL_STORAGE_VAR}-cookie-policy`, true);
		this.setState({ showInfo: false });
	};

	render() {
		return (
			<>
				<div className="main-screen">
					<div className="loginBasicList">
						<ul>
							<li>
								<a href="news">Event & News</a>
							</li>
							<li>
								<a href="/bidding-procedure">Bidding Procedure</a>
							</li>
							<li>
								<a href="Belgium KYC FORM.xls" download>
									Download KYC Form
								</a>
							</li>
							<li>
								<a href="/book-appointment">Take Appointment</a>
							</li>
							<li>
								<a href="/terms-condition">Terms</a>
							</li>
							<li>
								<a href="/contact-us">Contact Us</a>
							</li>
							<li>
								<a href="/career">Career</a>
							</li>
							<li>
								<a href="/about-us">About Us</a>
							</li>
						</ul>
					</div>
					<div className="loginMainWrapper">
						<div
							className="d-flex"
							style={{ width: "100%", height: "100%", alignItems: "flex-end" }}
						>
							<div className="loginLeftBlock">
								<video autoPlay loop muted>
									<source src={require("../assets/img/login/FSDLogoSight.mp4")}></source>
								</video>
								<div className="loginLeftBlockInner">
									<div className="d-flex main-box-img">
										<div className="video-width-img">
											<img
												src={require("../assets/img/signin-footer/forevermark.svg")}
												alt=""
												style={{ maxWidth: "250px", margin: "0 23px" }}
											/>
											<img
												src={require("../assets/img/signin-footer/arctic.svg")}
												alt=""
												style={{ maxWidth: "150px", margin: "0 23px" }}
											/>
											<img
												src={require("../assets/img/signin-footer/canadamark.svg")}
												alt=""
												style={{ maxWidth: "150px", margin: "0 23px" }}
											/>
											<img
												src={require("../assets/img/signin-footer/rio-tinto.svg")}
												alt=""
												style={{ maxWidth: "75px", margin: "0 23px" }}
											/>
											<a
												href="https://responsiblejewellery.com/member/finestar-jewellery-diamonds-pvt-ltd/"
												target="_blank"
												rel="noopener noreferrer"
												style={{ position: "relative", zIndex: 1 }}
											>
												<img
													src={require("../assets/img/signin-footer/responsible-council.svg")}
													alt=""
													style={{ maxWidth: "100px", margin: "0 23px" }}
												/>
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="LoginRightBlock">
								<LoginForm history={this.props.history} />
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default SignIn;
