import { logInfo } from "util/utils";

/**
 * Action class
 * Helper to create/emit/listen with CustomeEvents
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */
export class Action {
	static identifier = "Action";

	constructor(type) {
		this.type = type;
	}

	emit(detail) {
		const event = new CustomEvent(this.type, { detail });
		logInfo("EMIT", this.type, detail);
		window.dispatchEvent(event);
	}

	listen(_callback) {
		const callback = (event) => {
			logInfo("LISTEN", this.type, event?.detail);
			// return _callback(event, { type: this.type, data: event?.detail });
			return setTimeout(() => _callback(event, { type: this.type, data: event?.detail }));
		};

		const addListener = () => window.addEventListener(this.type, callback);
		const removeListener = () => window.removeEventListener(this.type, callback);
		addListener();
		return removeListener;
	}

	static initialize() {}
}
