import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notification } from "antd";
import axios from "axios";

import clone from "lodash/clone";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";

import { LocalStore, SessionStore } from "services/Storage";

import { history } from "util/history";
import { isNotEmpty, isString } from "util/utils";

import { USER_DATA, USER_TOKEN_SET } from "constants/ActionTypes";

import { store } from "appRedux/store";

import { setLoader } from "../../appRedux/actions/Common";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";
import {
	LOGIN_USER,
} from "../../constants/apiConstant";
import UtilService from "../../services/util";
import CheckBox from "../common/CheckBox";
import OpenNotification from "../common/CommonButton/OpenNotification";
import DownloadBlock from "../common/DownloadBlock";
import InputBlock from "../common/InputBlock";

class LoginForm extends Component {
	state = {
		formData: LocalStore.get("up") ?? { username: "", password: "" },
		rememberMe: Boolean(LocalStore.get("r-up")),
		checked: true,
		isLoading: false,
		rememberCheckVal: false,
		loading: false,
		error: null,
		verifyEmail: false,
		showModal: false,
		errors: {},
		userData: {},
		visible: false,
		Kyc: { imageOne: "", typeOne: "", imageTwo: "", typeTwo: "" },
		personalDocs: [],
		businessDocs: [],
	};

	componentDidMount() {
		LocalStore.deleteAll();
		SessionStore.deleteAll();

		const url = new URL(window.location.href);

		const username = url.searchParams.get("username");
		const password = url.searchParams.get("password");
		const source = url.searchParams.get("source");

		if (isString(username) && !isEmpty(username) && source === "oldwebsite") {
			const formData = { username, password, source };

			LocalStore.set("r-up", true);
			LocalStore.set("up", formData);

			history.push("/signin");
			window.location.reload();
			return;
		}

		const rememberUser = Boolean(LocalStore.get("r-up"));

		const user = LocalStore.get(`up`);
		if (user?.source) LocalStore.delete("r-up");

		if (rememberUser) {
			this.setState((state) => ({
				formData: { ...state.formData, ...LocalStore.get(`up`) },
				rememberMe: user.source ? false : rememberUser,
			}));
		}
	}

	getSetting = (data, settingType) => data?.find(({ type }) => type === settingType);

	handleSubmit = () => {
		const reqObj = clone(this.state.formData);

		if (this.handleValidation()) {
			// self.props.setLoader(true);
			const obj = {
				...LOGIN_USER,
				request: {
					...reqObj,
					masters: [
						"COLOR",
						"CLARITY",
						"SHAPE",
						"CUT",
						"FLUORESCENCE",
						"SHADE",
						"LAB",
						"POLISH",
						"SYMMETRY",
						"LOCATION",
						"BLACK_INCLUSION",
						"OPEN_INCLUSION_TABLE",
						"MILKEY",
						"WHITE_INCLUSION_CROWN",
						"OPEN_INCLUSION_CROWN",
						"EYECLEAN",
						"OPEN_INCLUSION_PAVILION",
						"ORIGIN",
						"BLACK_INCLUSION_CROWN",
						"H_AND_A",
						"WHITE_INCLUSION_TABLE",
						"FANCY_COLOR",
						"INTENSITY",
						"OVERTONE",
						"KEY_TO_SYMBOLS",
						"GIRDLE",
						"CULET",
						"GIRDLE_COND",
						"CULET_COND",
						"COMPANY_SIZE",
						"BLACK_INCLUSION_TABLE",
						"BLACK_INCLUSION_SIDE",
						"WHITE_INCLUSION_SIDE",
						"BRILLIANCY",
						"EYECLEAN",
						"BUSINESS_TYPE",
						"NATURE_OF_ORG",
						"DOC_TYPE_PERSONAL",
						"DOC_TYPE_BUSINESS",
					],
				},
			};

			this.setState({ isLoading: true });

			UtilService.callApi(obj, (err, data) => {
				this.setState({ isLoading: false });

				if (err) {
					OpenNotification({
						type: "error",
						title: data?.data?.message,
					});
				}
				if (data && data.code === "OK") {
					const permission = data?.data?.userPermissions?.data?.filter(isNotEmpty);

					if (isEmpty(permission))
						return OpenNotification({
							type: "error",
							title: `You don't have permission to access panel.`,
						});

					this.setState({ userData: data }, () => {
						if (data.data.user.kycRequired) {
							if (this.state.userData.data.user.account && isEmpty(this.state.userData.data.user.account.kyc)) {
								const { DOC_TYPE_PERSONAL, DOC_TYPE_BUSINESS } = data.data.masters;

								this.setState({
									personalDocs: DOC_TYPE_PERSONAL?.map(({ id, name }) => ({
										id: id[0],
										name,
									})),
									businessDocs: DOC_TYPE_BUSINESS?.map(({ id, name }) => ({
										id: id[0],
										name,
									})),
									visible: true,
								});
							} else {
								this.getRedirection(data);
							}
						} else {
							this.getRedirection(data);
						}
					});
				}
			});
		}
	};

	handleOnChange = (key, value) => {
		const { formData } = this.state;
		formData[key] = value;
		this.setState(formData);
	};

	getRedirection = (data) => {
		const user = data.data.user;

		const masters = data.data.masters;
		const columns = data.data.columns;
		const userPermissions = data.data.userPermissions.data;
		const keywords = data.data.keywords;
		const token = data.data.token.jwt;
		const terms = data.data.user.accountTerm;

		localStorage.setItem(`${LOCAL_STORAGE_VAR}-master`, JSON.stringify(masters));
		localStorage.setItem(`${LOCAL_STORAGE_VAR}-column`, JSON.stringify(columns));
		localStorage.setItem(`${LOCAL_STORAGE_VAR}-keywords`, JSON.stringify(keywords));
		localStorage.setItem(`${LOCAL_STORAGE_VAR}-terms`, JSON.stringify(terms));
		localStorage.setItem(`${LOCAL_STORAGE_VAR}-isTrusted`, data.data.isTrusted);

		if (this.state.rememberMe) {
			LocalStore.set("r-up", this.state.rememberMe);
			LocalStore.set("up", { username: this.state.formData.username, password: this.state.formData.password });
		} else {
			LocalStore.delete(["up", "r-up"]);
		}

		const setPermit = userPermissions?.filter(isNotEmpty)?.map((x) => {
			return { [x.module]: x.permissions };
		}) || {};	

		UtilService.setLocalStorageItem(setPermit, `${LOCAL_STORAGE_VAR}-userPermissions`);

		axios.defaults.headers.common["access-token"] = "JWT " + token;

		UtilService.setLocalStorageItem(user, `${LOCAL_STORAGE_VAR}-user`);
		store.dispatch({ type: USER_DATA, payload: user });

		UtilService.setLocalStorageItem(token, `${LOCAL_STORAGE_VAR}-token`);
		store.dispatch({ type: USER_TOKEN_SET, payload: token });

		if (LocalStore.get("last")) {
			history.push(LocalStore.get("last"));
			LocalStore.delete("last");
			return;
		}

		if (window.location.href.split("=")?.[1]) {
			history.push(window.location.href.split("=")?.[1]);
			return;
		}

		history.push("/");
	};

	handleValidation = () => {
		const formData = this.state.formData;
		const errors = this.state.errors;
		let formIsValid = true;
		const blankField = [];
		const invalidFiels = [];

		if (!formData["username"] || isEmpty(formData["username"])) {
			blankField.push("User Name");
			errors["username"] = true;
			formIsValid = false;
		}

		if (!formData["password"] || isEmpty(formData["password"])) {
			blankField.push("Password");
			errors["password"] = true;
			formIsValid = false;
		}

		if (blankField.length > 0) {
			const arr = blankField;
			let outStr = "";

			if (arr.length === 1) {
				outStr = arr[0];
			} else if (arr.length === 2) {
				outStr = arr.join(" and ");
			} else if (arr.length > 2) {
				outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
			}

			notification.error({
				message: "Required Fields",
				placement: "bottomLeft",
				description: outStr,
			});
		}

		if (invalidFiels.length > 0) {
			const arr = invalidFiels;
			let outStr = "";

			if (arr.length === 1) {
				outStr = arr[0];
			} else if (arr.length === 2) {
				outStr = arr.join(" and ");
			} else if (arr.length > 2) {
				outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
			}

			notification.error({
				message: "Invalid Fields",
				placement: "bottomLeft",
				description: outStr,
			});
		}
		this.setState({ errors: errors });
		return formIsValid;
	};

	render() {
		const { formData, errors } = this.state;
		return (
			<>
				<div className={`loginDetail ${this.state.isLoading ? "loading-indicator" : ""}`}>
					<div className="loginHead">
						<div className="wel_msg">
							<img src={require("../../assets/img/color-logo.png")} alt="color logo" />
						</div>
					</div>
					<div className="loginBox">
						<InputBlock
							onChange={(e) => this.handleOnChange("username", trim(e.target.value))}
							value={formData.username}
							type="text"
							label="USERNAME"
							placeholder="Username"
							hasError={errors["username"] ? true : false}
						/>
						<InputBlock
							onChange={(e) => this.handleOnChange("password", e.target.value)}
							value={formData.password}
							type="Password"
							label="PASSWORD"
							hasError={errors["password"] ? true : false}
							placeholder="Password"
							loginKeyDown={() => {
								if (formData.username && formData.password) {
									this.handleSubmit();
								}
							}}
						/>
						<div className="d-flex j-space-between align-items-center loginLinkBlock">
							<div className="rememberCheckBox">
								<CheckBox
									id="remember-check"
									checked={this.state.rememberMe}
									value={this.state.rememberMe}
									onChange={(e) => this.setState({ rememberMe: e.target.checked })}
								/>
								<span className="rememberLabel">Remember Me</span>
							</div>
						</div>
						<div className="loginButton">
							<button className="commonButton" onClick={this.handleSubmit.bind(this)}>
								Log In
							</button>
							<button
								onClick={() => {
									history.push("/guest-login");
								}}
								className="commonOutline"
							>
								Guest Login
							</button>
						</div>
					</div>
					<DownloadBlock />
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ auth }) => {
	const { token } = auth;
	return { token };
};

export default withRouter(connect(mapStateToProps, { setLoader })(LoginForm));
