import React from "react";
import { Col, Row } from "antd";

import { LAB_LINKS, VIEW_DNA_URL } from "constants/Common";
import { Unavailable } from "./MiscComponent";

function DrawTable({ fields, data = {} }) {
	const LAB_FILTER = LAB_LINKS[data?.lbNm]?.replace("***", data?.rptNo);

	return (
		<table border="1" className="stone-detail-tbl">
			<tbody>
				{fields.map((dt, i) => (
					<tr key={i}>
						<td>{`${dt.name}`}</td>
						<td>
							{dt.key === "lbNm" ? (
								<a
									// href={LAB_FILTER} target="_blank"
									rel="noopener noreferrer"
									style={{ color: "rgb(0, 140, 186)", fontWeight: 600 }}
								>
									{data[dt.key] ? data[dt.key] : "-"}
								</a>
							) : dt.key === "isDna" ? (
								<a
									href={`${VIEW_DNA_URL}/dna/${data?.vStnId}`}
									target="_blank"
									rel="noopener noreferrer"
									style={{ color: "rgb(0, 140, 186)", fontWeight: 600, textDecoration: 'underline' }}
								>
									DNA
								</a>
							) : (
								data?.[dt.key] || "-"
							)}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

function StoneDetails({ dnaData }) {
	const tbl1 = [
		{ name: "Lab", key: "lbNm" },
		{ name: "Shape", key: "shpNm" },
		{ name: "Carat", key: "crt" },
		{ name: "Clarity", key: "clrNm" },
		{ name: "Color", key: "colNm" },
		{ name: "Cut", key: "cutNm" },
		{ name: "Pol", key: "polNm" },
		{ name: "Symm", key: "symNm" },
		{ name: "FIs", key: "fluNm" },
	];

	const tbl2 = [
		{ name: "DNA", key: "isDna" },
		{ name: "Table%", key: "tblPer" },
		{ name: "Depth%", key: "depPer" },
		{ name: "LWD", key: "msrmnt" },
		{ name: "Ratio", key: "ratio" },
		{ name: "Girdle", key: "girdleStr" },
		{ name: "Culet", key: "cultNm" },
		{ name: "Brilliancy", key: "brlncyNm" },
		{ name: "Laser Ins. ", key: "WRAP_LASER_INC" },
	];

	return dnaData?.hideDna !== "DETAILS" ? (
		<Row gutter={8}>
			<Col span={12}>
				<DrawTable fields={tbl1} data={dnaData} />
			</Col>

			<Col span={12}>
				<DrawTable fields={tbl2} data={dnaData} />
			</Col>
		</Row>
	) : (
		<Unavailable msg="Stone Details Not Available!" />
	);
}

export default React.memo(StoneDetails);
