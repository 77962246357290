import React from "react";

import "./title.less";

const Heading = (props) => {
	return (
		<h2 className={`mainHeadingTitle ${props.className ? props.className : ""}`}>
			{props.title}
		</h2>
	);
};
export default Heading;
