import UtilService from "./util";
import _ from "lodash";

import { PROJECT_SETTINGS_TYPE } from "../constants/Common";
import { SessionStore } from "./Storage";
import { getPath, getProjectSetting } from "components/DiamondList/DiamondListFunctions";

const Common = {
	DiamondTrackType(req, api, cb) {
		const obj = {
			method: "post",
			url: api,
			request: req,
		};

		UtilService.callApi(obj, (err, data) => {
			if (data && data.code === "OK") {
				cb(null, data);
			} else {
				cb(err, null);
			}
		});
	},
};
export default Common;

export const getMediaUrl = async (type, diamData = {}) => {
	let mediaUrl = SessionStore.get("media-path-pattern") ?? "";

	if (!mediaUrl) {
		await getProjectSetting(PROJECT_SETTINGS_TYPE.MEDIA_PATH, (data) => {
			mediaUrl = data.MEDIA_PATH_PATTERN;
			SessionStore.set("media-path-pattern", mediaUrl);
		});
	}

	let url = mediaUrl[type].FILE_PATH;
	
	if (type === "videoFile" && getPath() === 'compare-dna' && !_.includes(url.PATH, "&sv=5,4,3,2,1")) {
		url.PATH += "&sv=5,4,3,2,1";
	}

	let KEY = diamData[url.KEY];
	if (diamData.lbNm === "AGS") {
		KEY = diamData["AGS_REPORT_NO"]
	}
	const fileUrl = url.PATH.replaceAll("***", KEY);
	return fileUrl;
};
