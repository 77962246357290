import React, { useState } from "react";
import { Tabs } from "antd";

import VideoBlock from "../VideoBlock";
import ImageBlock from "../ImageBlock";

const { TabPane } = Tabs;

function VisualDisplay(props) {
	const { dnaData, tabIndex, changeTab } = props;

	const [currentIndex, setCurrentIndex] = useState(0);

	return (
		<Tabs type="card" onChange={(key) => changeTab(key)} activeKey={tabIndex}>
			<TabPane tab="Video" key="1">
				<div className="box-container">
					<VideoBlock dnaData={dnaData} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
				</div>
			</TabPane>

			<TabPane tab="Image" key="2">
				<div className="box-container img">
					<ImageBlock dnaData={dnaData} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
				</div>
			</TabPane>
		</Tabs>
	);
}

export default React.memo(VisualDisplay);
