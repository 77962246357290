import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";

import Auth from "./Auth";
import Commonm from "./Common";
import Diamond from "./diamond";

const reducers = combineReducers({
	routing: routerReducer,
	auth: Auth,
	commonData: Commonm,
	diamondData: Diamond,
});

export default reducers;
