import combineURLs from "axios/lib/helpers/combineURLs";
import _ from "lodash";

import OpenNotification from "components/common/CommonButton/OpenNotification";

import UtilService from "services/util";

import { isEmpty, isNotEmpty } from "util/utils";

import {
	BASE_DOWNLOAD_URL,
	LOCAL_STORAGE_VAR,
	DOWNLOAD_URL,
} from "constants/Common";
import {
	EXPORT_EXCEL,
	SEND_EMAIL_XRAY,
	DOWNLOAD_ZIP,
	DOWNLOAD_FILE,
	PROJECT_SETTING,
} from "constants/apiConstant";

export const LISTINGPAGES = {
	DETAILS: "diamond-details",
};

export function getPath() {
	return _.last(window.location.pathname.split("/"));
}

export function isMobile() {
	return window.innerWidth <= 767 ? true : false;
}

export function handleAlter(array, item, key) {
	let checked = _.cloneDeep(array) || [];
	if (_.find(checked, { [key]: item[key] })) checked = checked.filter((el) => el[key] !== item[key]);
	else checked.push(item);
	return checked;
}

export const handleDownloadExcel = (
	filter = {},
	id = [],
	cb,
	isUpcoming,
	quoteApplied,
	orderDiamond = false,
	path = null,
) => {
	const request = isEmpty(id) ? { filter } : { id, filter };
	if (isNotEmpty(id)) {
		filter = {};
	}

	if (!isEmpty(quoteApplied)) request.quoteDiamonds = quoteApplied;
	if (isUpcoming) request.isUpcoming = true;
	if (orderDiamond) request.orderDiamond = true;
	if ((path === "new-arrival-bid-it" || path === "diamond-list") && isEmpty(filter)) request.finalCalc = true;

	const objData = { ...EXPORT_EXCEL, request };

	UtilService.callApi(objData, async (err, res) => {
		if (err) {
			cb(false);
			return OpenNotification({ type: "error", title: err.data.message });
		}

		if (res && res.code === "OK") {
			const file_path = combineURLs(combineURLs(DOWNLOAD_URL, "data"), res?.data?.data);
			window.open(file_path, "Download");
			cb(true);
		}
	});
};

export function compareStones(list) {
	if (list.length === 0) {
		OpenNotification({
			type: "error",
			title: "Please select stone(s) to compare.",
		});
		return;
	}

	if (list.length > 15) {
		OpenNotification({
			type: "error",
			title: "Maximum 15 stones can be compared.",
		});
		return;
	}
	let data = [];
	list.map((x) => data.push(window.location.pathname === "/account/confirm-list" ? x.diamondId : x.id));

	data = _.uniq(data);

	if (data.length === 1) {
		OpenNotification({
			type: "error",
			title: "Please select minimum two stones to compare.",
		});
		return;
	}

	if (data.length > 15) {
		data = data.slice(data.length - 15, data.length);
	}
	localStorage.setItem(`${LOCAL_STORAGE_VAR}-compare-diamond`, JSON.stringify(data));

	if (window.location.pathname === "/account/confirm-list") {
		localStorage.setItem(`${LOCAL_STORAGE_VAR}-confirm-compare`, JSON.stringify(true));
	} else {
		localStorage.setItem(`${LOCAL_STORAGE_VAR}-confirm-compare`, JSON.stringify(false));
	}
	localStorage.setItem(`${LOCAL_STORAGE_VAR}-now`, JSON.stringify(window.location.pathname + window.location.search));
	window.open("/compare");
}

export const handleSendEmail = (apiObj, cb) => {
	const obj = { ...EXPORT_EXCEL, request: apiObj };

	UtilService.callApi(obj, (err, res) => {
		if (res && res.code === "OK") {
			cb(true);
			return OpenNotification({ type: "success", title: res.message });
		} else {
			cb(false);
			return OpenNotification({ type: "error", title: err.data.message || res.message });
		}
	});
};

export const handleSendXRayEmail = (apiObj, path, cb) => {
	apiObj.message = `${apiObj.message} <a href=${path}> View File</a>`;
	document.body.classList.add("loading-indicator");
	const obj = {
		...SEND_EMAIL_XRAY,
		request: apiObj,
	};

	UtilService.callApi(obj, (_err, data) => {
		document.body.classList.remove("loading-indicator");
		if (data && data.code === "OK") {
			document.body.classList.remove("loading-indicator");
			cb(true);
			return OpenNotification({
				type: "success",
				title: data.message,
			});
		} else {
			cb(false);
			return OpenNotification({
				type: "error",
				title: data.message,
			});
		}
	});
};

export const downloadZip = (dt, cb) => {
	const obj = { ...DOWNLOAD_ZIP, request: dt };

	document.body.classList.add("loading-indicator");

	UtilService.callApi(obj, (err, res) => {
		document.body.classList.remove("loading-indicator");

		if (res && res.code === "OK") {
			const path = dt.zip ? res.data : res.data[0];
			const file_path = combineURLs(DOWNLOAD_URL, path);

			if (dt) {
				const xhr = new XMLHttpRequest();
				xhr.open("GET", file_path, true);
				xhr.responseType = "blob";

				const fileName = _.last(path.split("/"));

				xhr.onload = function () {
					const urlCreator = window.URL || window.webkitURL;
					const imageUrl = urlCreator.createObjectURL(this.response);
					const tag = document.createElement("a");
					tag.href = imageUrl;
					tag.download = fileName;
					document.body.appendChild(tag);
					tag.click();
					document.body.removeChild(tag);
				};

				xhr.onerror = (err) => {
					document.body.classList.remove("loading-indicator");
					return OpenNotification({
						type: "error",
						title: "This file is not available !",
					});
				};

				setTimeout(() => {
					xhr.send();
					document.body.classList.remove("loading-indicator");
				}, 5000);
			} else {
				if (dt?.isExcel || dt?.certFile || dt?.type2) window.open(file_path, "Download");
			}
			cb(true);
		} else if (res && res.code === "E_NOT_FOUND") {
			OpenNotification({
				type: "error",
				title: res.message,
			});
			cb(false);
		} else {
			OpenNotification({
				type: "error",
				title: err.data.message || res.message,
			});
			cb(false);
		}
	});
};

export const downloadFile = (apiObj) => {
	const obj = {
		...DOWNLOAD_FILE,
		request: apiObj,
	};
	document.body.classList.add("loading-indicator");

	UtilService.callApi(obj, (err, data) => {
		if (data && data.code === "OK") {
			const server = BASE_DOWNLOAD_URL;
			const file_path = server + data.data.path;

			const xhr = new XMLHttpRequest();
			xhr.open("GET", file_path, true);
			xhr.responseType = "blob";

			xhr.onload = function () {
				const urlCreator = window.URL || window.webkitURL;
				const imageUrl = urlCreator.createObjectURL(this.response);
				const tag = document.createElement("a");
				tag.href = imageUrl;
				tag.download = apiObj.fileName;
				document.body.appendChild(tag);
				tag.click();
				document.body.removeChild(tag);
			};

			xhr.onerror = (err) => {
				document.body.classList.remove("loading-indicator");
				return OpenNotification({
					type: "error",
					title: "This file is not available !",
				});
			};

			setTimeout(() => {
				xhr.send();
				document.body.classList.remove("loading-indicator");
			}, 5000);
		} else {
			return OpenNotification({
				type: "error",
				title: err.data.message || data.message,
			});
		}
	});
};

export const downloadVideo = (apiObj) => {
	const xhr = new XMLHttpRequest();
	xhr.open("GET", apiObj.path, true);
	xhr.responseType = "blob";

	xhr.onload = function () {
		const urlCreator = window.URL || window.webkitURL;
		const imageUrl = urlCreator.createObjectURL(this.response);
		const tag = document.createElement("a");
		tag.href = imageUrl;
		tag.target = "_blank";
		tag.download = apiObj.fileName + apiObj.ext;
		document.body.appendChild(tag);
		tag.click();
		document.body.removeChild(tag);
	};

	xhr.onerror = (err) => {
		document.body.classList.remove("loading-indicator");
		return OpenNotification({
			type: "error",
			title: "This file is not available !",
		});
	};

	setTimeout(() => {
		xhr.send();
		document.body.classList.remove("loading-indicator");
	}, 5000);
};

export function getProjectSetting(type, cb) {
	const obj = PROJECT_SETTING;

	obj.request = {
		filter: {
			type,
		},
		finalcount: true,
	};
	return UtilService.callApi(obj, (err, data) => {
		if (data && data.code === "OK" && type === 6) cb(data.data);
		else if (data && data.code === "OK") cb(data.data.data);
		else cb([]);
	});
}

export function shareExcel(checked, cb, searchId) {
	const obj = {
		...EXPORT_EXCEL,
		request: {
			id: checked,
			searchId: searchId,
		},
	};

	UtilService.callApi(obj, (err, data) => {
		if (data && data.code === "OK") {
			cb(data.data.data);
		} else {
			OpenNotification({
				type: "error",
				title: err.data.message || data.message,
			});
		}
	});
}
