import React, { useEffect, useState } from "react";
import { Tabs } from "antd";

import { getMediaUrl } from "services/Commonfunction";

import { Unavailable } from "./MiscComponent";

const { TabPane } = Tabs;

function ViewCert(props) {
	const { dnaData = {}, tabIndex, changeTab } = props;

	const [allCerts, setAllCerts] = useState([]);

	useEffect(() => {
		async function fetchData() {
			const dnaCerts = [];

			if (dnaData.certFile) {
				const url = await getMediaUrl("certFile", dnaData);
				dnaCerts.push({
					title: dnaData.lbNm,
					url,
				});
			}

			if (dnaData?.altCert) {
				await Promise.all(
					dnaData.altCert.map(async (dt) => {
						if (dt.certFile) {
							const url = await getMediaUrl("certFile", dt);
							dnaCerts.push({
								title: dt.lbNm,
								url,
							});
						}
					}),
				);
			}

			setAllCerts([...dnaCerts]);
		}

		fetchData();
	}, [dnaData]);

	return allCerts.length === 0 ? (
		<Unavailable msg="Certificate Not Available!" styles={{ height: "656px" }} />
	) : (
		<Tabs type="card" onChange={(key) => changeTab(key)} activeKey={tabIndex}>
			{allCerts.map((cert, i) => (
				<TabPane tab={cert.title} key={cert.title}>
					<div className="box-container view-cert">
						<iframe title="url" src={cert.url} alt="" />
					</div>
				</TabPane>
			))}

			{allCerts.findIndex((v) => v.title === tabIndex) === -1 && (
				<TabPane tab="‎" key={tabIndex}>
					<div className="box-container view-cert">
						<Unavailable msg="Certificate Not Available!" />
					</div>
				</TabPane>
			)}
		</Tabs>
	);
}

export default React.memo(ViewCert);
