import React, { useEffect, useState } from "react";
import { Card } from "antd";
import Slider from "react-slick";

import { getMediaUrl } from "services/Commonfunction";

import MagnifierImage from "components/DiamondDetail/MagnifierImage";
import { NextArrow, PrevArrow, Unavailable } from "./MiscComponent";

export default function DiamondImg({ dnaData = {}, navFor, setNav, filter }) {
	const [sliderImages, setSliderImages] = useState([]);

	const sliderSettings = {
		fade: true,
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
	};

	useEffect(() => {
		async function fetchData() {
			let imagesFilter = filter.slice();

			const sliderData = await Promise.all(
				imagesFilter.map(async (obj) => {
					let url = "";
					if (obj.key === "pltFile" || obj.key === "darkFieldImage" || obj.key === "idealWhiteImage") {
						if (dnaData.img) url = await getMediaUrl(obj.value, dnaData);
					} else if (obj.key === "flsImage") {
						if (dnaData.fluNm && dnaData.fluNm !== "NON") url = await getMediaUrl(obj.value, dnaData);
					} else if (dnaData[obj.value]) {
						url = await getMediaUrl(obj.value, dnaData);
					}

					return {
						...obj,
						url,
					};
				}),
			);

			setSliderImages(sliderData);
		}

		fetchData();
	}, [filter.length, dnaData, filter]);

	return sliderImages.length > 0 ? (
		<Card size="small" bordered={false} className="img-vid-container diam-images">
			<Slider asNavFor={navFor} ref={setNav} {...sliderSettings}>
				{sliderImages.map((dt, i) => (
					<div
						key={i}
						onClick={() => {
							window.open(`${dt.viewFile}/${dnaData?.id}`);
						}}
					>
						<MagnifierImage image={dt.url} />

						<Card.Meta title={dt.title} className="text-center mt-5" />
					</div>
				))}
			</Slider>
		</Card>
	) : (
		<Unavailable msg="Data Not Available" styles={{ height: "405px" }} />
	);
}
