import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";

import OpenNotification from "components/common/CommonButton/OpenNotification";
import { getMediaUrl } from "services/Commonfunction";

const VideoBlock = ({ ...props }) => {
	const { dnaData, setCurrentIndex, currentIndex } = props;
	const [videoUrl, setVideoUrl] = useState("");

	const slider = useRef(null);

	useEffect(() => {
		slider.current.slickGoTo(0);

		// Create a flag to track mounting state
		let isMounted = true;

		async function fetchData() {
			try {
				const url = await getMediaUrl("videoFile", dnaData);

				// Check if the component is still mounted before updating the state
				if (isMounted) {
					setVideoUrl(url);
				}
			} catch (error) {
				OpenNotification({
					type: "error",
					title: error.message,
				});
			}
		}

		// Call the asynchronous function
		fetchData();

		// Cleanup function to set the mounted flag to false when the component is unmounted
		return () => {
			isMounted = false;
		};
	}, [dnaData]);

	useEffect(() => {
		slider.current.slickGoTo(currentIndex);
	}, [currentIndex]);

	return (
		<>
			<div>
				<Slider
					className="sliderMainImage"
					arrows={false}
					ref={slider}
					beforeChange={(_current, next) => setCurrentIndex(next)}
				>
					{dnaData?.videoFile ? (
						<div className="imageSliderFullBlock">
							<iframe title="img1" src={videoUrl} />
						</div>
					) : (
						<div className="imageSliderFullBlock no-msg">Video Coming Soon</div>
					)}
				</Slider>
			</div>
		</>
	);
};

export default VideoBlock;
