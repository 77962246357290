import { LocalStore } from "services/Storage";

import {
	USER_TOKEN_SET,
} from "../../constants/ActionTypes";
import { LOCAL_STORAGE_VAR } from "../../constants/Common";

const defaultState = {
	token: undefined,
	initURL: undefined,
	authUser: undefined,
	masterData: undefined,
	keywordsData: undefined,
};

const initialState = {
	...defaultState,
	token: LocalStore.get("token"),
	authUser: LocalStore.get("user"),
	masterData: JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)),
	keywordsData: JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-keywords`)),
};

export default (state = initialState, action) => {
	switch (action.type) {
		case USER_TOKEN_SET: {
			return {
				...state,
				token: action.payload,
			};
		}

		case "@RESET": {
			return { ...defaultState };
		}

		default:
			return state;
	}
};
