import React from "react";
import "./input.less";

const InputBlock = (props) => {
  let groupClass = "from-group inputBlock";

  // Omitting `hasError` from the spread props
  const { hasError, label, placeholder, value, type, onChange, loginKeyDown, ...otherProps } = props;

  if (hasError) {
    groupClass = groupClass + " error-message";
  }

  return (
    <div className={groupClass}>
      {label ? <label>{label}</label> : null}
      <div className="inputWrapper">
        <input
          placeholder={placeholder}
          value={value ? value : ""}
          type={type ? type : "text"}
          onChange={onChange ? onChange : null}
          onKeyDown={(e) => {
            if (e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
              type === "number" && e.preventDefault();
            }

            if (e.keyCode === 13) {
              loginKeyDown && loginKeyDown();
            }
          }}
          {...otherProps} // Spread other props here
        />
      </div>
    </div>
  );
};

export default InputBlock;
