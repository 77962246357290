import React from "react";
import Slider from "react-slick";
import "./diamondfullview.less";

const DiamondFullVideoBlock = ({ ...props }) => {

	const slider1 = React.useRef(null);
	const { dnaData, setCurrentIndex, currentIndex, getMediaUrl } = props;

	React.useEffect(() => {
		slider1.current.slickGoTo(0);
	}, []);

	React.useEffect(() => {
		slider1.current.slickGoTo(currentIndex);
	}, [currentIndex]);

	return (
		<>
			<div>
				<Slider
					className="sliderMainImage"
					arrows={false}
					ref={slider1}
					beforeChange={(current, next) => setCurrentIndex(next)}
				>
					{dnaData.videoFile ? (
						<div>
							<iframe title="img1" src={getMediaUrl("videoFile")} style={{ border: "none" }}/>
						</div>
					) : (
						<div className="imageSliderFullBlock no-msg">Video Coming Soon</div>
					)}

					{dnaData.tiltVideo ? (
						<div>
							<video autoPlay loop muted name="media">
								<source title="img2" src={getMediaUrl("tiltVideo")}></source>
							</video>
						</div>
					) : (
						<div className="imageSliderFullBlock no-msg">Video Coming Soon</div>
					)}
				</Slider>
			</div>
		</>
	);
};

export default DiamondFullVideoBlock;
