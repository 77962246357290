// Customizer const
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
// Sales person
export const SALES_DATA = "SALES_DATA";

//Auth const
export const RESEND_OTP = "RESEND_OTP";
export const USER_DATA = "user_data";
export const CURRENT_PAGE_DATA = "CURRENT_PAGE_DATA";
export const USER_TOKEN_SET = "user_token_set";
export const CATCH_MASTER_DATA = "CATCH_MASTER_DATA";

//diamond deatails
export const DIAMOND_DATA = "DIAMOND_DETAILS";
export const DIAMONDID = "DIAMONDID";

// reminder-page actions
export const SET_COUNT = "SET_COUNT";
export const SET_SELECT = "SET_SELECT";
export const RESET_SELECT = "RESET_SELECT";

/**
 * switing loader status
 */

export const SET_LOADER = "SET_LOADER";
export const PAGE_PERMISSION = "PAGE_PERMISSION";

export const RESET_SEARCH = "RESET_SEARCH";
export const MODIFY_SEARCH = "MODIFY_SEARCH";
