import React from "react";

const NoDataShow = ({ message, style }) => {
  return (
    <div className="empty-data" style={style ? style : null}>
      <h3>{message ? message : "No data found."}</h3>
    </div>
  );
};

export default NoDataShow;
