import React, { Component } from "react";
import "./pageNotFound.less";

class ThankYou extends Component {
	render() {
		return (
			<div className="thankYouWrapper">
				<div className="thankYouInnerBox">
					<div className="thankYouMainBox">
						<div className="thankYouImgBlock">
							<img src={require("../../assets/svg/ThankYou/1.svg")} alt="" />
						</div>
						<div className="thankYouPageContent">
							<h2>Oops, Page not found!</h2>

							<a href="/">
								<button className="commonButton">Go Back</button>
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ThankYou;
