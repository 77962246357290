import { Action } from "services/Action";

import { compose, hasKey, isArray, isEmpty, isString, logWarn } from "util/utils";

/**
 * Storage class
 * Transforms and stores data in this.driver
 * @author Sagar Panchal <panchal.sagar@outlook.com>
 */
class Storage {
	identifier = undefined;
	storage = undefined;

	encodeSequence = [JSON.stringify];
	decodeSequence = [JSON.parse];

	constructor(storage, options) {
		this.setStorage(storage);
		this.setEncodeSequence(options?.encodeSequence);
		this.setDecodeSequence(options?.decodeSequence);
	}

	setStorage(value) {
		if (!(value instanceof window.Storage)) {
			throw new Error("Storage must be an instance of window.Storage class");
		}

		switch (value) {
			case undefined:
				this.identifier = undefined;
				this.driverPrefix = undefined;
				break;

			case localStorage:
				this.identifier = "LocalStorage";
				this.driverPrefix = "local";
				break;

			case sessionStorage:
				this.identifier = "SessionStorage";
				this.driverPrefix = "session";
				break;

			default:
				this.identifier = "OtherStorage";
				this.driverPrefix = "other";
				break;
		}

		this.storage = value;
	}

	setEncodeSequence(value) {
		if (isArray(value) && !isEmpty(value)) this.encodeSequence = value;
	}

	setDecodeSequence(value) {
		if (isArray(value) && !isEmpty(value)) this.decodeSequence = value;
	}

	get updateKeyEvent() {
		return new Action(`@storage/${this.driverPrefix}/key-updated`);
	}

	get deleteKeyEvent() {
		return new Action(`@storage/${this.driverPrefix}/key-deleted`);
	}

	get storagePrefix() {
		return `fs-${this.driverPrefix}-`;
	}

	getStorageKey(key) {
		return key.startsWith(this.storagePrefix) ? key : `${this.storagePrefix}${key}`;
	}

	getSenitizedKey(key) {
		return key.startsWith(this.storagePrefix) ? key.substr(this.storagePrefix?.length) : key;
	}

	decodeValue(value, toBinary = true) {
		const decoder = toBinary ? compose(...this.decodeSequence) : JSON.parse;
		const decodedValue = decoder(value)?._;
		return decodedValue;
	}

	encodeValue(value, toAscii = true) {
		const encoder = toAscii ? compose(...this.encodeSequence) : JSON.stringify;
		const encodedValue = encoder({ _: value });
		return encodedValue;
	}

	/**
	 * Store
	 * @param {String} id
	 * @param {*} value
	 */
	set(id, value, encode = true) {
		const name = this.getSenitizedKey(id);
		const key = this.getStorageKey(name);

		try {
			this.storage.setItem(key, this.encodeValue(value, encode));
			this.updateKeyEvent.emit({ [name]: value });
			return true;
		} catch (error) {
			logWarn(error);
		}
	}

	/**
	 * Retrieve
	 * @param {String} name
	 */
	get(name, decode = true) {
		const key = this.getStorageKey(name);

		try {
			const value = this.storage.getItem(key);
			return value === null ? value : this.decodeValue(value, decode);
		} catch (error) {
			logWarn(error);
			return null;
		}
	}

	/**
	 * Remove values by key
	 * @param {String|Array} list String or Array of string
	 */
	delete(list) {
		list = isArray(list) ? list : isString(list) ? [list] : [];

		list.forEach((id) => {
			const name = this.getSenitizedKey(id);
			const key = this.getStorageKey(id);

			try {
				this.storage.removeItem(key);
				this.storage.removeItem(name);
				this.updateKeyEvent.emit({ [name]: undefined });
				this.deleteKeyEvent.emit({ [name]: undefined });
			} catch (error) {
				logWarn(error);
			}
		});
	}

	deleteAll(except = ["up", "r-up", "last"]) {
		const storageKeys = Object.keys(this.storage).map((key) => this.getSenitizedKey(key));
		except.forEach((name) => storageKeys.splice(storageKeys.indexOf(name), 1));
		this.delete(storageKeys);
	}

	listen(id, callback) {
		const name = this.getSenitizedKey(id);
		const unlisten = this.updateKeyEvent.listen((event) => {
			if (!hasKey(event?.detail, name)) return;
			callback({ [name]: event?.detail?.[name] });
		});
		return unlisten;
	}

	initialize() {}
}

export const LocalStore = new Storage(localStorage, {
	encodeSequence: [btoa, unescape, encodeURIComponent, JSON.stringify],
	decodeSequence: [JSON.parse, decodeURIComponent, escape, atob],
});

export const SessionStore = new Storage(sessionStorage, {
	encodeSequence: [btoa, unescape, encodeURIComponent, JSON.stringify],
	decodeSequence: [JSON.parse, decodeURIComponent, escape, atob],
});

if (process.env.REACT_APP_ENV === "development") {
	window.LocalStore = LocalStore;
	window.SessionStore = SessionStore;
}
